import Repository from './Repository';

export default class WorksheetRepository extends Repository {
    protected basePath = 'worksheets';

    protected messages = {
        update : this.__('Worksheet successfully updated.'),
    }

    protected redirects = {
        store: { name : 'worksheets' }
    }

    public destroy(id: string, data: object = null): Promise {
        console.error('Destroy method does not exist on WorksheetRepository.');
    }

    public downloadFiles(id: string, params: object = null): Promise {
        if (!this.basePath) {
            console.error('Request: no basePath defined.');

            return;
        }

        return new Promise((resolve, reject) => {
            this.request.get(this.buildPath(this.basePath + '/' + id + '/files', params), { responseType: 'blob' })
                .then((response : any) => {
                    const blob = new Blob([
                        response.data
                    ], { type:'application/zip' });
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;

                    a.download = 'files.zip';
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                })
                .catch(error => reject(error));
        });
    }

    public downloadTaskFiles(id: string, params: object = null): Promise {
        if (!this.basePath) {
            console.error('Request: no basePath defined.');

            return;
        }

        return new Promise((resolve, reject) => {
            this.request.get(this.buildPath(this.basePath + '/tasks/' + id + '/files', params), { responseType: 'blob' })
                .then((response : any) => {
                    const blob = new Blob([
                        response.data
                    ], { type:'application/zip' });
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;

                    a.download = 'files.zip';
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                })
                .catch(error => reject(error));
        });
    }

    public index(params: object = null): Promise {
        console.error('Index method does not exist on WorksheetRepository.');
    }

    public export(params: object = null): Promise {
        console.error('Export method does not exist on WorksheetRepository.');
    }

    public import(data: object): Promise {
        console.error('Import method does not exist on WorksheetRepository.');
    }

    public store(data: object, params: object = null) {
        console.error('Store method does not exist on WorksheetRepository.');
    }
}