<template>
    <c-modal class="max-w-[600px]" v-bind:open="true" v-bind:title="__('New serial number')" v-on:closeModal="closeDestination">
        <template v-slot:body>
            <div class="flex flex-col">
                <c-control-text class="mb-10" name="sn.sn" required
                                v-bind:invalid="!!requestStore.errors?.code"
                                v-bind:label="__('Serial number (S/N)')"
                                v-model="sn.sn"
                />

                <c-control-text class="mb-10" name="sn.name"
                                v-bind:invalid="!!requestStore.errors?.name"
                                v-bind:label="__('Local name')"
                                v-model="sn.name"
                />

                <c-control-autocomplete class="mb-10" icon="search" label-field="name" name="partner"
                                        dusk="partner-autocomplete"
                                        v-bind:invalid="!partner && !!requestStore.errors?.partner_site_id"
                                        v-bind:label="__('Partner')"
                                        v-bind:placeholder="__('Search by partner name or tax ID')"
                                        v-bind:source-get="partnerGet"
                                        v-bind:source-search="partnerSearch"
                                        v-model="partner"
                />

                <c-control-select class="mb-10" name="sn.site"
                                  v-bind:invalid="!!requestStore.errors?.partner_site_id"
                                  v-bind:label="__('Site')"
                                  v-bind:options="sites"
                                  v-if="partner"
                                  v-model="sn.partner_site_id"
                />
            </div>
        </template>
        <template v-slot:footer>
            <div class="bg-white flex justify-end w-full">
                <c-button class="bg-slate-100 mr-2 text-slate-800" v-on:click="closeDestination">
                    {{ __('Cancel') }}
                </c-button>
                <c-button class="bg-success text-white" v-on:click="submit">
                    {{ __('Save') }}
                </c-button>
            </div>
        </template>
    </c-modal>
</template>

<script setup lang="ts">
    import { inject, ref, watch } from 'vue';
    import { useRoute, useRouter } from 'vue-router'
    import {
        CButton,
        CControlAutocomplete,
        CControlSelect,
        CControlText,
        CModal,
    } from '@teamfurther/cinderblock';

    import PartnerRepository from '../../../repositories/PartnerRepository';
    import SerialNumberRepository from '../../../repositories/SerialNumberRepository';
    import { useRequestStore } from '../../../stores/request';
    import { useSessionStore } from '../../../stores/session';

    const __ = inject('__');
    const partner = ref<object>(null);
    const partnerRepository = PartnerRepository.getInstance();
    const route = useRoute();
    const partNumberId = route.params.pn;
    const serialNumberRepository = SerialNumberRepository.getInstance();
    const sites = ref<Object[]>();

    const sn = ref<object>({
        name: '',
        part_number_id: partNumberId,
        partner_site_id: '',
        sn: '',
    });

    const requestStore = useRequestStore();
    const router = useRouter();
    const sessionStore = useSessionStore();

    function closeDestination() {
        if (!window.history.state.back) {
            router.push({ name : 'devices.pn' });
        } else {
            router.go(-1);
        }
    }

    async function partnerGet(partnerId) {
        if (!partnerId.value) {
            return null;
        }

        return await partnerRepository.show(partnerId.value.id);
    }

    async function partnerSearch(val) {
        return await partnerRepository.index({
            filters : {
                search: val
            },
            include: ['sites']
        });
    }

    async function submit() {
        await serialNumberRepository.store(sn.value);
    }

    watch(() => partner.value, () => {
        sites.value = partner.value?.sites.map((site) => {
            return {
                label : site['name'],
                value : site['id'],
            }
        });
    });
</script>
