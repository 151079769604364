<template>
    <div class="container flex flex-col h-screen justify-center px-1 py-10">
        <img class="h-6 mb-5 md:mb-8 mx-auto" src="/img/logo.svg" v-bind:alt="app.name" />
        <form class="bg-white border border-slate-200 max-w-[400px] mb-20 mx-auto p-5 rounded shadow-sm w-full"
              v-on:submit.prevent="submit"
        >
            <c-control-text class="mb-10" name="email" required type="email"
                            v-bind:invalid="!!requestStore.errors?.email"
                            v-bind:label="__('Email')"
                            v-bind:placeholder="__('placeholders.email')"
                            v-model="email"
            />

            <c-control-text class="mb-10" name="password" placeholder="**********" required type="password"
                            v-bind:invalid="!!requestStore.errors?.password"
                            v-bind:label="__('Password')"
                            v-model="password"
            />

            <c-button class="bg-highlight text-white" dusk="login" submit>{{ __('Login') }}</c-button>
            <router-link class="ml-5 text-highlight text-sm" v-bind:to="{ name : 'forgot-password' }">
                {{ __('Forgotten password?') }}
            </router-link>
        </form>
    </div>
</template>

<script setup lang="ts">
    import { onMounted, ref } from 'vue';
    import { useRoute } from 'vue-router';
    import {
        CButton,
        CControlText,
    } from '@teamfurther/cinderblock';

    import { useRequestStore }  from '../../stores/request';
    import { useSessionStore }  from '../../stores/session';

    const email = ref<string>('');
    const password = ref<string>('');
    const requestStore = useRequestStore();
    const route = useRoute();
    const sessionStore = useSessionStore();

    async function submit() {
        await sessionStore.logUserIn(email.value, password.value);
    }

    onMounted(async () => {
        if (sessionStore.user) {
            requestStore.redirect({ name: 'errors.403' });
        }

        if (route.params.token) {
            await sessionStore.logUserInByToken(route.params.token);
        }
    });
</script>
