import Repository from './Repository';

export default class TicketRepository extends Repository {
    protected basePath = 'tickets';

    protected messages = {
        destroy : this.__('Ticket successfully deleted.'),
        duplicate : this.__('Ticket successfully duplicated.'),
        store : this.__('Ticket successfully created.'),
        submit : this.__('Ticket successfully submitted.'),
        update : this.__('Ticket successfully updated.'),
    }

    protected redirects = {
        store: { name : 'tickets' }
    }

    public checkScheduleOverlaps(ticket: object): Promise {
        if (!this.basePath) {
            console.error('Request: no basePath defined.');

            return;
        }

        return new Promise((resolve, reject) => {
            this.request.post(this.buildPath(this.basePath + '/time/check-overlap'), ticket)
                .then((response : any) => {
                    resolve(response.data);
                })
                .catch(error => reject(error));
        });
    }

    public duplicate(id: string, data: object, params: object = null): Promise {
        if (!this.basePath) {
            console.error('Request: no basePath defined.');

            return;
        }

        return new Promise((resolve, reject) => {
            this.request.post(this.buildPath(this.basePath + '/' + id + '/duplicate', params), data)
                .then((response : any) => {
                    this.request.message = this.messages.duplicate;
                    this.request.status = 200;
                    this.request.title = this.__('Success');

                    resolve(response.data);
                })
                .catch(error => reject(error));
        });

    }

    public export(params: object = null): Promise {
        console.error('Export method does not exist on TicketRepository.');
    }

    public import(data: object): Promise {
        console.error('Import method does not exist on TicketRepository.');
    }

    public submit(data: object, params: object = null) {
        if (!this.basePath) {
            console.error('Request: no basePath defined.');

            return;
        }

        return new Promise((resolve, reject) => {
            this.request.post(this.buildPath(this.basePath + '/' + data.tenant_id, params), data)
                .then((response : any) => {
                    this.request.message = this.messages.submit;
                    this.request.status = 200;
                    this.request.title = this.__('Success');

                    resolve(response.data);
                })
                .catch(error => reject(error));
        });
    }
}