import { defineStore } from 'pinia';

export const useCurrencyStore = defineStore('currency', () => {

    function format(amount, currency) {
        if (!amount && amount !== 0) return '';

        switch (currency) {
            case 'huf':
            case 'HUF': {
                return amount.toLocaleString('hu') + ' Ft';
            }
            case 'eur':
            case 'EUR': {
                return '€' + amount.toFixed(2);
            }
        }
    }

    return { format }
});
