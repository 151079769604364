<template>
    <c-modal class="max-w-[600px]" v-bind:open="true" v-bind:title="__('New product')" v-on:closeModal="closeDestination">
        <template v-slot:body>
            <div class="flex flex-col">
                <c-control-text class="mb-10" name="product.code" required
                                v-bind:invalid="!!requestStore.errors?.code"
                                v-bind:label="__('Article number')"
                                v-model="product.code"
                />

                <c-control-text class="mb-10" name="product.name" required
                                v-bind:invalid="!!requestStore.errors?.name"
                                v-bind:label="__('Name')"
                                v-model="product.name"
                />

                <div class="grid grid-cols-2 gap-10 mb-10">
                    <c-control-number class="mb-10" name="product.quantity" required
                                      v-bind:invalid="!!requestStore.errors?.quantity"
                                      v-bind:label="__('Quantity')"
                                      v-model="product.quantity"
                    />

                    <c-control-select class="mb-10" name="product.um" required
                                      v-bind:invalid="!!requestStore.errors?.um"
                                      v-bind:label="__('Unit')"
                                      v-bind:options="unitOptions"
                                      v-model="product.um"
                    />
                </div>

                <div class="grid grid-cols-2 gap-10">
                    <c-control-number class="mb-10" name="product.cost_price"
                                      v-bind:invalid="!!requestStore.errors?.cost_price"
                                      v-bind:label="__('Cost price')"
                                      v-model="product.cost_price"
                    />

                    <c-control-number class="mb-10" name="product.sale_price"
                                      v-bind:invalid="!!requestStore.errors?.sale_price"
                                      v-bind:label="__('Sale price')"
                                      v-model="product.sale_price"
                    />
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <div class="bg-white flex justify-end w-full">
                <c-button class="bg-slate-100 mr-2 text-slate-800" v-on:click="closeDestination">
                    {{ __('Cancel') }}
                </c-button>
                <c-button class="bg-success text-white" v-on:click="submit">
                    {{ __('Save') }}
                </c-button>
            </div>
        </template>
    </c-modal>
</template>

<script setup lang="ts">
    import { inject, onMounted, ref } from 'vue';
    import { useRouter } from 'vue-router'
    import {
        CButton,
        CControlNumber,
        CControlSelect,
        CControlText,
        CModal,
    } from '@teamfurther/cinderblock';

    import InventoryRepository from '../../repositories/InventoryRepository';
    import { useRequestStore } from '../../stores/request';
    import { useSessionStore } from '../../stores/session';

    const __ = inject('__');
    const inventoryRepository = InventoryRepository.getInstance();

    const product = ref<object>({
        code: '',
        cost_price: 0,
        name: '',
        quantity: 1,
        um: 'pcs',
        sale_price: 0,
    });

    const requestStore = useRequestStore();
    const router = useRouter();
    const sessionStore = useSessionStore();
    const unitOptions = ref<object[]>([]);

    function closeDestination() {
        if (!window.history.state.back) {
            router.push({ name : 'inventory' });
        } else {
            router.go(-1);
        }
    }

    async function submit() {
        await inventoryRepository.store(product.value);
    }

    onMounted(() => {
        unitOptions.value = sessionStore.user.tenant.config.units.split(', ').map(unit => {
            return {
                label: __('units.' + unit),
                value: unit,
            }
        });
    });
</script>
