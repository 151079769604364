import { useSessionStore }  from '../stores/session';

export const hasSubscription = {
    install: (app, options) => {
        app.config.globalProperties.hasSubscription = function (value: string): boolean {
            const sessionStore = useSessionStore();

            if (!sessionStore.user || !sessionStore.user.tenant) {
                return false;
            }

            if (sessionStore.user.tenant.is_on_generic_trial) {
                return true;
            }

            if (!sessionStore.user.tenant.is_subscribed) {
                return false;
            }

            return sessionStore.user.tenant.subscription_plan['product'] === value;
        };
    },
};