<template>
    <div class="container px-1 py-10">
        <form
            class="bg-white border border-slate-200 max-w-[600px] mx-auto p-5 rounded shadow-sm"
            v-on:submit.prevent="submit"
        >
            <img
                class="h-6 mb-5 md:mb-10"
                src="/img/logo.svg"
                v-bind:alt="app.name"
            />

            <p class="mb-10 text-center text-slate-400 text-sm">
                {{
                    __(
                        "Enter the email address associated with your account, and we will send you an email with instructions to reset your password."
                    )
                }}
            </p>

            <c-control-text
                class="mb-10"
                name="email"
                required
                type="email"
                v-bind:invalid="!!requestStore.errors?.email"
                v-bind:label="__('Email')"
                v-bind:placeholder="__('placeholders.email')"
                v-model="email"
            />

            <c-button class="bg-highlight text-white" submit>{{
                __("Reset password")
            }}</c-button>
            <router-link
                class="ml-5 text-highlight text-sm"
                v-bind:to="{ name: 'login' }"
            >
                {{ __("Login") }}
            </router-link>
        </form>
    </div>
</template>

<script setup lang="ts">
    import { onMounted, ref } from 'vue';
    import { useRoute } from 'vue-router';
    import {
        CButton,
        CControlText,
    } from '@teamfurther/cinderblock';

    import { useRequestStore } from '../../stores/request';
    import { useSessionStore } from '../../stores/session';

    const email = ref<string>('');
    const requestStore = useRequestStore();
    const sessionStore = useSessionStore();

    async function submit() {
        await sessionStore.sendPasswordReset(email.value);
    }

    onMounted(() => {
        if (sessionStore.user) {
            requestStore.redirect({ name: 'errors.403' });
        }

        const route = useRoute();

        if (route.query.email) {
            email.value = route.query.email as string;
        }
    });
</script>
