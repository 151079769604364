import Repository from './Repository';

export default class PartnerRepository extends Repository {
    protected basePath = 'partners';

    protected messages = {
        destroy : this.__('Partner successfully deleted.'),
        import : this.__('Partners successfully imported.'),
        store : this.__('Partner successfully created.'),
        update : this.__('Partner successfully updated.'),
    }

    protected redirects = {
        import: { name : 'partners' },
        store: { name : 'partners.edit', params: { partner: '$id' } },
    }

    public export(params: object = null): Promise {
        console.error('Export method does not exist on PartnerRepository.');
    }
}