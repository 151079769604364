import Repository from './Repository';

export default class BoardListRepository extends Repository {
    protected basePath = 'boards';

    protected messages = {
        destroy: this.__('Board successfully deleted.'),
        reorder: this.__('Board order changed successfully.'),
        store: this.__('Board successfully created.'),
        update: this.__('Board successfully updated.'),
    }

    protected redirects = {}

    public export(params: object = null): Promise {
        console.error('Export method does not exist on BoardRepository.');
    }

    public import(data: object): Promise {
        console.error('Import method does not exist on BoardRepository.');
    }

    public reorder(data: object) {
        if (!this.basePath) {
            console.error('Request: no basePath defined.');

            return;
        }

        return new Promise((resolve, reject) => {
            this.request.post(this.buildPath(this.basePath + '/reorder'), data)
                .then((response : any) => {
                    this.request.message = this.messages.reorder;
                    this.request.status = 200;
                    this.request.title = this.__('Success');

                    resolve(response.data);
                })
                .catch(error => reject(error));
        });
    }

    public show(id: string, params: object = null): Promise {
        console.error('Show method does not exist on BoardRepository.');
    }
}