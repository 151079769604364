<template>
    <div class="container px-1 py-5">
        <div class="flex items-center justify-between mb-5 md:mb-10">
            <div>
                <h1 class="lg:text-2xl text-xl" v-if="pn.make || pn.model">{{ pn.make }} {{ pn.model }}</h1>
                <h2 class="text-slate-500 text-sm">P/N: {{ pn.pn }}</h2>
            </div>

            <div class="shrink-0" v-if="!floatSave">
                <c-button class="bg-slate-200 mr-2 text-slate-500" router v-bind:to="{ name : 'devices.pn' }">
                    {{ __('Back') }}
                </c-button>
                <c-button class="bg-success lg:min-w-[200px] text-white" dusk="save-button" v-on:click="submit">
                    {{ __('Save') }}
                </c-button>
            </div>
            <c-button class="bg-success fixed right-5 rounded-full top-5 z-10" v-else v-on:click="submit">
                <c-icon class="h-6 stroke-white w-6" icon="save" />
            </c-button>
        </div>

        <div class="bg-white mb-4 p-2 rounded shadow-sm">
            <h2 class="font-medium mb-5 text-lg">{{ __('General information') }}</h2>

            <c-control-text class="mb-10" name="pn" required
                            v-bind:invalid="!!requestStore.errors?.pn"
                            v-bind:label="__('Part number (P/N)')"
                            v-model="pn.pn"
            />

            <div class="grid lg:grid-cols-2 gap-10">
                <c-control-text name="make"
                                v-bind:invalid="!!requestStore.errors?.make"
                                v-bind:label="__('Make')"
                                v-model="pn.make"
                />

                <c-control-text name="model"
                                v-bind:invalid="!!requestStore.errors?.model"
                                v-bind:label="__('Model')"
                                v-model="pn.model"
                />
            </div>
        </div>

        <div class="bg-white mb-4 p-2 rounded shadow-sm">
            <h2 class="font-medium mb-5 text-lg">{{ __('Maintenance interval') }}</h2>

            <div class="flex items-center mb-2">
                {{ __('Every') }}
                <c-control-number class="mx-2 w-12" name="pn.maintenance_interval"
                                  v-bind:invalid="!!requestStore.errors?.maintenance_interval"
                                  v-bind:min="0"
                                  v-model="pn.maintenance_interval"
                />

                <c-control-select class="mr-5" name="pn.maintenance_frequency"
                                  v-bind:invalid="!!requestStore.errors?.maintenance_frequency"
                                  v-bind:options="frequencies"
                                  v-model="pn.maintenance_frequency"
                />
                {{ __('or') }}
                <c-control-number class="ml-5 mr-2 w-16" name="pn.maintenance_usage"
                                  v-bind:invalid="!!requestStore.errors?.maintenance_usage"
                                  v-bind:min="0"
                                  v-model="pn.maintenance_usage"
                />
                {{ __('h of usage') }}
            </div>
            <span class="text-slate-500 text-sm">
                {{ __('In case both date and usage intervals are set, whichever occurs first will trigger alert.') }}
            </span>
        </div>

        <div class="bg-white mb-4 p-2 rounded shadow-sm">
            <div class="flex flex-col items-end justify-between lg:flex-row lg:items-center mb-10">
                <div class="flex items-center grow">
                    <h2 class="font-medium mr-10 text-lg">{{ __('Serial numbers') }}</h2>

                    <c-control-text
                        class="grow md:max-w-[300px]"
                        icon="search"
                        name="search"
                        v-bind:placeholder="__('Search by name or S/N')"
                        v-model="s"
                        v-on:keyup="search"
                    />
                </div>

                <div class="flex items-center shrink-0">
                    <c-button class="bg-highlight rounded-br-none rounded-tr-none shrink-0 text-white"
                              dusk="create-pn-button"
                              router
                              v-if="can('devices.store')"
                              v-bind:to="{ name : 'devices.sn.create' }"
                    >
                        + {{ __('Add serial number') }}
                    </c-button>
                    <c-button class="bg-highlight rounded-br-none rounded-tr-none shrink-0 text-white"
                              dusk="create-pn-button"
                              v-if="!can('devices.store') && can('devices.import')"
                    >
                        + {{ __('Import from XLS') }}
                    </c-button>
                    <div class="after:absolute after:border-[5px] after:border-transparent after:border-t-white after:content-[''] after:h-0 after:mt-1 after:left-1/2 after:pointer-events-none after:top-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:w-0 px-4 bg-highlight border-l border-indigo-800 cursor-pointer h-9 hover:bg-indigo-700 py-2.5 relative rounded-br rounded-tr"
                         v-if="can('devices.import|devices.store')"
                         v-on:click="showAddMenu = !showAddMenu"
                         v-on-click-outside="closeAddMenu"
                    >
                        <nav class="absolute bg-white border border-slate-200 flex flex-col min-w-[150px] mt-2 right-0 rounded shadow-sm text-sm top-full whitespace-nowrap z-10"
                             v-show="showAddMenu"
                        >
                            <router-link class="flex border-b border-slate-200 items-center p-2"
                                         v-bind:to="{ name : 'devices.sn.create' }"
                                         v-if="can('devices.store')"
                            >
                                <c-icon class="h-4 mr-2 stroke-slate-800 w-4" icon="plus" />{{ __('Add serial number') }}
                            </router-link>
                            <a class="flex hover:bg-slate-50 items-center p-2"
                                         v-bind:href="'/qr/downloadAll/' + sessionStore.user?.tenant.id + '/' + pn.id"
                            >
                                <c-icon class="h-4 mr-2 stroke-slate-800 w-4" icon="printer" />
                                {{ __('Print QR codes') }}
                            </a>
                            <a class="flex hover:bg-slate-50 items-center p-2"
                               v-if="can('devices.import')"
                               v-on:click="showImportDialog"
                            >
                                <c-icon class="h-4 mr-2 stroke-slate-800 w-4" icon="file-plus" />
                                {{ __('Import from XLS') }}
                            </a>
                            <input class="hidden" id="import-dialog" type="file"
                                   v-on:change="importFile"
                            />
                            <a class="flex hover:bg-slate-50 items-center p-2"
                               v-if="can('devices.import')"
                               v-bind:href="'/templates/' + langStore.locale + '/devices/sn'"
                            >
                                <c-icon class="h-4 mr-2 stroke-slate-800 w-4" icon="arrow-down" />
                                {{ __('Download XLS template') }}
                            </a>
                        </nav>
                    </div>
                </div>
            </div>

            <ul class="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4" dusk="part-number-serial-numbers-list">
                <li class="bg-white border border-slate-200 group/item flex items-center leading-tight relative rounded shadow-sm"
                    v-for="(sn, key) in pn.serial_numbers"
                >
                    <router-link class="flex flex-col h-16 justify-center pl-2 pr-8 py-2 relative w-full"
                                 v-bind:to="{ name : 'devices.sn.edit', params : { pn : partNumberId, sn: sn.id } }"
                                 v-if="can('devices.update')"
                    >
                        <span class="font-semibold line-clamp-1 mb-1" v-if="sn.name">{{ sn.name }}</span>
                        <span class="overflow-hidden text-ellipsis text-sm text-slate-500 whitespace-nowrap">
                            {{ sn.sn }}
                        </span>

                        <span class="absolute cursor-help right-1 top-1"
                              v-if="sn.needs_maintenance"
                              v-on:mouseover="snTooltip = sn.id"
                              v-on:mouseout="snTooltip = null"
                        >
                            <c-icon class="h-6 stroke-error w-6" icon="alert-triangle" />
                            <c-tooltip position="top" v-bind:active="snTooltip === sn.id">
                                {{ __('Needs maintenance.') }}
                            </c-tooltip>
                        </span>
                    </router-link>

                    <div class="absolute -bottom-1 group/card-actions right-1"
                         dusk="kebab-menu"
                         v-if="can('devices.update')"
                    >
                        <span class="cursor-pointer flex h-6 hover:bg-slate-200 items-center justify-center mb-2 rounded w-6"
                              dusk="serial-number-actions"
                              v-on:click="showCardActions = []; showCardActions[sn.id] = !showCardActions[sn.id]"
                              v-on-click-outside="key === 0 ? closeCardActions : [() => {}]"
                        >
                            <c-icon class="h-5 pointer-events-none stroke-slate-800 w-5" icon="more-vertical" />
                        </span>
                        <div class="absolute bg-slate-700 border border-slate-200 bottom-0 hidden overflow-hidden right-0 rounded shadow-sm translate-y-full z-10"
                             v-bind:class="{ '!block' : showCardActions[sn.id] }"
                        >
                            <router-link class="border-b border-b-slate-500 flex hover:bg-slate-800 items-center p-2 text-white text-xs whitespace-nowrap"
                                         dusk="edit-serial-number-button"
                                         v-bind:to="{ name : 'devices.sn.edit', params : { pn : partNumberId, sn: sn.id } }"
                                         v-if="can('devices.update')"
                            >
                                <c-icon class="h-4 mr-2 stroke-white w-4" icon="edit-3" />{{ __('Edit') }}
                            </router-link>
                            <a class="cursor-pointer flex hover:bg-slate-800 items-center p-2 text-red-300 text-xs whitespace-nowrap"
                               dusk="delete-serial-number-button"
                               v-if="can('devices.update')"
                               v-on:click="deletedSn = sn.id"
                            >
                                <c-icon class="h-4 mr-2 stroke-red-300 w-4" icon="trash-2" />{{ __('Delete') }}
                            </a>
                        </div>
                    </div>
                </li>
                <li class="bg-white border border-slate-200 group/item flex items-center leading-tight relative rounded shadow-sm">
                    <router-link class="flex h-16 items-center justify-center p-2 w-full"
                                 v-bind:to="{ name : 'devices.sn.create', pn: partNumberId }"
                                 v-if="can('devices.store')"
                    >
                        <c-icon class="group-hover:stroke-slate-500 h-10 stroke-slate-300 w-10" icon="plus" />
                    </router-link>
                </li>
            </ul>
        </div>

        <div class="bg-white mb-4 p-2 rounded shadow-sm">
            <h2 class="font-medium mb-5 text-lg">{{ __('Tasks') }}</h2>

            <ul class="mb-2" dusk="tasks-container">
                <li class="cursor-pointer flex group hover:text-error items-center mb-1 relative text-sm w-full"
                    v-for="(task, key) in pn.tasks"
                    v-on:click="deleteTask(key)"
                    v-on:mouseover="selectedTask = key"
                    v-on:mouseout="selectedTask = null"
                >
                    <c-icon class="group-hover:stroke-error h-4 mr-2 stroke-slate-500 transition-none w-4"
                            v-bind:icon="selectedTask === key ? 'trash-2' : 'square'"
                    />
                    <span>{{ task.name }}</span>
                </li>
            </ul>
            <div class="flex items-center mt-4 w-full">
                <c-control-text class="mr-2 w-full" name="addedTask"
                                v-bind:placeholder="__('Task description')"
                                v-model="addedTask"
                                v-on:keyup.enter="addTask"
                />
                <c-button class="bg-highlight" dusk="add-task-button"
                          v-bind:class="{ 'bg-slate-800' : addedTask === '' }"
                          v-bind:disabled="addedTask === ''"
                          v-on:click="addTask"
                >
                    <c-icon class="h-3 stroke-white w-3" icon="corner-down-left" />
                </c-button>
            </div>
        </div>

        <div class="bg-white mb-4 p-2 rounded shadow-sm">
            <h2 class="font-medium mb-5 text-lg">{{ __('Files') }}</h2>

            <div class="grid grid-cols-3 gap-4 md:grid-cols-6" dusk="files-container">
                <a class="bg-white border border-slate-200 cursor-pointer duration-500 flex flex-col group hover:border-slate-500 items-center justify-center p-2 relative rounded transition-all" target="_blank"
                   v-bind:href="file.url"
                   v-for="(file, key) in pn.files"
                >
                    <img class="max-h-32 rounded"
                         v-bind:alt="file.name"
                         v-bind:src="file.url"
                         v-if="isImage(file.name)"
                    />
                    <c-icon class="group-hover:stroke-slate-500 h-10 stroke-slate-300 w-10" icon="file"
                            v-else
                    />

                    <span class="block mt-1 overflow-hidden text-center text-ellipsis text-xs whitespace-nowrap w-full">
                                {{ file.name }}
                            </span>

                    <span class="absolute bg-error cursor-pointer hidden group-hover:block p-1 -right-2 rounded-full text-sm -top-2 text-error" dusk="delete-file-button"
                          v-on:click.prevent="deleteFile(key)"
                    >
                                <c-icon class="h-4 stroke-white w-4" icon="trash-2" />
                            </span>
                </a>

                <a class="bg-white border border-slate-200 cursor-pointer duration-500 flex flex-col group hover:border-slate-500 items-center justify-center p-2 relative rounded transition-all" target="_blank"
                   v-bind:href="newFiles[key]"
                   v-for="(file, key) in newFilenames"
                >
                    <img class="max-h-32 rounded"
                         v-bind:alt="file"
                         v-bind:src="newFiles[key]"
                         v-if="isImage(file)"
                    />
                    <c-icon class="group-hover:stroke-slate-500 h-10 stroke-slate-300 w-10" icon="file"
                            v-else
                    />

                    <span class="block mt-1 overflow-hidden text-center text-ellipsis text-xs whitespace-nowrap w-full">
                        {{ file }}
                    </span>

                    <span class="absolute bg-error cursor-pointer hidden group-hover:block p-1 -right-2 rounded-full text-sm -top-2 text-error"
                          v-on:click.prevent="deleteFile(key, true)"
                    >
                        <c-icon class="h-4 stroke-white w-4" icon="trash-2" />
                    </span>
                </a>

                <a class="bg-white border border-slate-200 cursor-pointer duration-500 flex group hover:border-slate-500 items-center justify-center p-2 rounded transition-all"
                   v-on:click="upload"
                >
                    <c-icon class="group-hover:stroke-slate-500 h-10 stroke-slate-300 w-10" icon="plus" />
                </a>
            </div>
            <c-control-file class="h-0 opacity-0 pointer-events-none w-0" multiple name="pn.files" v-bind:filenames="newFilenames" v-model="newFiles" />
        </div>

        <div class="bg-white mb-4 p-2 rounded shadow-sm">
            <h2 class="font-medium mb-5 text-lg">{{ __('Spare parts') }}</h2>

            <c-control-tag name="pn.parts"
                           v-bind:invalid="!!requestStore.errors?.parts"
                           v-bind:label-field="(data) => data.name + ' (' + data.code + ')'"
                           v-bind:placeholder="__('Search by name or article number')"
                           v-bind:source-get="inventoryGet"
                           v-bind:source-search="inventorySearch"
                           v-model="pn.parts"
            />
        </div>

        <div class="bg-white p-2 rounded shadow-sm" v-if="fields.length > 0 && pn.fieldValues">
            <h2 class="font-medium text-lg">{{ __('Other information') }}</h2>

            <template v-for="field in fields">
                <div class="mt-5">
                    <div class="bg-indigo-50 group mb-1 p-2 rounded"
                         v-for="repetition in computeFieldRepetitions(field)"
                         v-if="field.fields && field.fields.length > 0"
                    >
                        <div class="flex items-center justify-between mb-4">
                            <strong class="text-sm">{{ field.label }}</strong>
                            <a class="cursor-pointer duration-500 group-hover:pointer-events-auto group-hover:visible group/icon hover:bg-error invisible p-1 pointer-events-none rounded transition-colors"
                               v-if="repetition > 1"
                               v-on:click="deleteFieldRepetition(field, repetition)"
                            >
                                <c-icon class="group-hover/icon:stroke-white h-4 stroke-error w-4" icon="trash-2" />
                            </a>
                        </div>
                        <div class="mb-5"
                             v-for="subfield in field.fields"
                        >
                            <custom-field v-bind:field="subfield"
                                          v-bind:id="field.key + '.' + (repetition-1) + '.' + subfield.key"
                                          v-model="pn.fieldValues[field.key + '.' + (repetition-1) + '.' + subfield.key]"
                            />
                        </div>
                        <a class="bg-highlight cursor-pointer ml-auto p-1 rounded table"
                           v-if="field.repeatable && repetition === computeFieldRepetitions(field)"
                           v-on:click="addFieldRepetition(field)"
                        >
                            <c-icon class="h-6 stroke-white w-6" icon="plus" />
                        </a>
                    </div>
                    <custom-field v-bind:field="field"
                                  v-bind:id="field.key"
                                  v-else
                                  v-model="pn.fieldValues[field.key]"
                    />
                </div>
            </template>
        </div>
    </div>
    <c-modal class="md:w-[450px]" v-bind:open="deletedSn !== null" v-bind:title="__('Delete serial number')" v-on:closeModal="deletedSn = null">
        <template v-slot:body>
            <div class="py-5 text-center text-sm">
                {{ __('Are you sure you want to delete this serial number?') }}
            </div>
        </template>
        <template v-slot:footer>
            <div class="flex justify-end w-full">
                <c-button class="bg-slate-100 mr-2 text-slate-800" v-on:click="deletedSn = null">
                    {{ __('Cancel') }}
                </c-button>
                <c-button class="bg-error text-white" dusk="delete-contact-modal-button" v-on:click="del">
                    {{ __('Delete') }}
                </c-button>
            </div>
        </template>
    </c-modal>
</template>

<script setup lang="ts">
    import { inject, isRef, onMounted, ref, watch } from 'vue';
    import { useRoute } from 'vue-router';
    import { vOnClickOutside } from '@vueuse/components';
    import debounce from 'debounce';
    import {
        CButton,
        CControlFile,
        CControlNumber,
        CControlSelect,
        CControlTag,
        CControlText,
        CIcon,
        CModal,
        CTooltip,
    } from '@teamfurther/cinderblock';

    import CustomField from '../../../components/CustomField.vue';
    import InventoryRepository from '../../../repositories/InventoryRepository';
    import PartNumberRepository from '../../../repositories/PartNumberRepository';
    import SerialNumberRepository from '../../../repositories/SerialNumberRepository';
    import { useLocalizationStore } from '../../../stores/localization';
    import { useRequestStore } from '../../../stores/request';
    import { useSessionStore } from '../../../stores/session';

    const __ = inject('__');
    const addedTask = ref<string>('');

    const closeCardActions = [
        (e) => {
            if (
                !e.target.parentNode.className?.toString().includes('group/card-actions')
            ) {
                showCardActions.value = [];
            }
        }
    ];

    const deletedSn = ref(null);
    const fields = ref<Object[]>([]);
    const floatSave = ref<boolean>(false);

    const frequencies = [
        {
            value: 'days',
            label: __('day(s)'),
        },
        {
            value: 'months',
            label: __('month(s)'),
        },
        {
            value: 'years',
            label: __('year(s)'),
        },
    ];

    const inventoryRepository = InventoryRepository.getInstance();
    const langStore = useLocalizationStore();
    const newFilenames = ref<String[]>([]);
    const newFiles = ref<Object[]>([]);
    const route = useRoute();
    const partNumberId = route.params.pn;
    const partNumberRepository = PartNumberRepository.getInstance();

    const pn = ref<object>({
        make: '',
        maintenance_frequency: 'months',
        maintenance_interval: 0,
        maintenance_usage: 0,
        model: '',
        parts: [],
        pn : '',
        tasks: [],
    });

    const requestStore = useRequestStore();
    const s = ref<string>('');

    const search = debounce(async () => {
        pn.value.serial_numbers = await serialNumberRepository.index({
            filters: {
                part_number_id: partNumberId,
                search : s.value,
            },
        });
    }, 250);

    const selectedTask = ref<string>();
    const serialNumberRepository = SerialNumberRepository.getInstance();
    const sessionStore = useSessionStore();
    const showAddMenu = ref<boolean>(false);
    const showCardActions = ref<boolean[]>([]);
    const snTooltip = ref<string | null>(null);

    function addFieldRepetition(field) {
        const currentRepetitions = computeFieldRepetitions(field);

        field.fields.forEach(subfield => {
            pn.value.fields.push({
                key: field.key + '.' + currentRepetitions + '.' + subfield.key,
                value: null,
                part_number_id: pn.value.id,
            });
        });

        computePnFieldValues();
    }

    function addTask() {
        pn.value.tasks.push({
            name: addedTask.value,
            part_number_id: pn.value.id
        });

        addedTask.value = '';
    }

    function closeAddMenu() {
        showAddMenu.value = false;
    }

    function computeFieldRepetitions(field) {
        let repetitions = null;

        pn.value.fields.forEach(f => {
            const keyParts = f.key.split('.');

            if (keyParts[0] === field.key && (!repetitions || parseInt(keyParts[1]) + 1 > repetitions)) {
                repetitions = parseInt(keyParts[1]) + 1;
            }
        });

        return repetitions;
    }

    function computePnFieldValues() {
        fields.value.forEach(field => {
            if (field.fields) {
                field.fields.forEach(subfield => {
                    const found = pn.value.fields.findIndex(f => {
                        return f.key === field.key + '.0.' + subfield.key;
                    });

                    if (found === -1) {
                        pn.value.fields.push({
                            key: field.key + '.0.' + subfield.key,
                            value: null,
                            part_number_id: pn.value.id,
                        });
                    }
                });
            } else {
                const found = pn.value.fields.findIndex(f => {
                    return f.key === field.key;
                });

                if (found === -1) {
                    pn.value.fields.push({
                        key: field.key,
                        value: null,
                        part_number_id: pn.value.id,
                    });
                }
            }
        });

        pn.value.fieldValues = pn.value.fields.reduce((accumulator, field) => {
            accumulator[field.key] = field.value;

            return accumulator;
        }, {});
    }

    async function del() {
        await serialNumberRepository.destroy(deletedSn.value);

        let snsTemp = pn.value.serial_numbers;
        pn.value.serial_numbers.forEach((sn, key) => {
            if (sn.id === deletedSn.value) {
                snsTemp.splice(key, 1);
            }
        });
        pn.value.serial_numbers = snsTemp;

        deletedSn.value = null;
    }

    function deleteFieldRepetition(field, repetition) {
        const repetitions = computeFieldRepetitions(field);

        field.fields.forEach(subfield => {
            pn.value.fields = pn.value.fields.filter(f => {
                return f.key !== field.key + '.' + (repetition - 1) + '.' + subfield.key;
            });
        });

        for (let i = repetition; i < repetitions; i++) {
            field.fields.forEach(subfield => {
                let subject = pn.value.fields.findIndex(f => {
                    return f.key === field.key + '.' + i + '.' + subfield.key;
                });

                pn.value.fields[subject].key = field.key + '.' + (i - 1) + '.' + subfield.key;
            });
        }

        computePnFieldValues();
    }

    function deleteFile(key: number, isNew: boolean = false) {
        if (!isNew) {
            pn.value.files.splice(key, 1);
        } else {
            newFilenames.value.splice(key, 1);
            newFiles.value.splice(key, 1);
        }
    }

    function deleteTask(key) {
        pn.value.tasks.splice(key, 1);
    }

    async function importFile(e) {
        const input: HTMLInputElement = (<HTMLInputElement>e.target);

        if (input && input.files) {
            const data: FormData = new FormData();
            data.append('file', input.files[0]);
            data.append('part_number_id', partNumberId);

            await serialNumberRepository.import(data);
        } else {
            console.error('Request: no file found.')
        }
    }

    async function inventoryGet(inventory) {
        if (isRef(inventory) && inventory.value) {
            return await inventoryRepository.show(inventory.value.id);
        } else if (inventory && inventory.id) {
            return await inventoryRepository.show(inventory.id);
        } else {
            return null;
        }
    }

    async function inventorySearch(val) {
        return await inventoryRepository.index({
            filters : {
                search: val
            },
        });
    }

    function showImportDialog() {
        showAddMenu.value = false;

        document.querySelector('#import-dialog').click();
    }

    async function submit() {
        pn.value.newFilenames = newFilenames.value;
        pn.value.newFiles = newFiles.value;

        pn.value = await partNumberRepository.update(partNumberId, pn.value, {
            include: [
                'fields',
                'files',
                'parts',
                'serialNumbers',
                'tasks',
            ]
        });

        computePnFieldValues();

        newFilenames.value = [];
        newFiles.value = [];
    }

    function upload() {
        document.getElementById('pn.files').click();
    }

    onMounted(async () => {
        await requestStore.get('devices/pn/fields').then(async (response : any) => {
            fields.value = response.data;
        });

        pn.value = await partNumberRepository.show(partNumberId, {
            include: [
                'fields',
                'files',
                'parts',
                'serialNumbers',
                'tasks',
            ]
        });

        computePnFieldValues();

        if (pn.value.maintenance_interval === null) {
            pn.value.maintenance_frequency = 'months';
            pn.value.maintenance_interval = 0;
        }

        if (pn.value.maintenance_usage === null) {
            pn.value.maintenance_usage = 0;
        }

        window.addEventListener('scroll', () => {
            floatSave.value = window.scrollY > 100;
        });
    });

    watch(() => pn.value, () => {
        Object.keys(pn.value.fieldValues).forEach(key => {
            let field = pn.value.fields.find(f => f.key === key);

            if (field) {
                field.value = pn.value.fieldValues[key];
            }
        });
    }, { deep: true });
</script>
