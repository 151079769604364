<template>
    <div class="container px-1 py-5">
        <div class="flex flex-col items-start justify-between mb-10 md:flex-row md:items-center">
            <div class="flex flex-col mb-5 md:mb-0 md:flex-row md:items-center w-full">
                <h1 class="lg:text-3xl mb-5 md:mb-0 mr-10 text-2xl">{{ __('Inventory reports') }}</h1>

                <c-control-text class="grow-0 mb-5 md:mb-0 md:mr-10 md:w-[300px]" icon="search" name="search"
                                v-bind:placeholder="__('Search by name or article number')"
                                v-model="s"
                                v-on:keyup="search"
                />

                <div class="flex items-center justify-between">
                    <div class="relative">
                        <a class="cursor-pointer duration-500 flex hover:decoration-dotted hover:underline items-center p-1 rounded text-sm transition-all"
                           v-bind:class="{ 'bg-highlight text-white' : Object.keys(filters).length }"
                           v-on:click="openFilters = true"
                        >
                            <c-icon class="h-5 mr-2 stroke-slate-800 w-5" icon="filter"
                                    v-bind:class="{ '!stroke-white' : Object.keys(filters).length }"
                            />
                            {{ __('Filter results') }}
                            <span class="bg-white flex font-bold h-4 items-center justify-center ml-1 rounded-full text-center text-highlight text-xs w-4"
                                  v-if="Object.keys(filters).length"
                            >
                                {{ Object.keys(filters).length }}
                            </span>
                        </a>
                        <div class="absolute bg-white border border-slate-200 hidden md:w-[500px] mt-1 rounded shadow-lg w-[calc(100vw-0.5rem)] z-30"
                             v-bind:class="{ '!block' : openFilters }"
                             v-on-click-outside="closeFilters"
                        >
                            <div class="flex">
                                <nav class="bg-slate-50 border-r border-slate-200 flex flex-col shrink-0 text-sm">
                                    <a class="border-b border-slate-200 cursor-pointer px-2 py-4"
                                       v-bind:class="{ 'after:absolute after:border after:border-[7px] after:border-transparent after:border-l-white after:content-[\'\'] after:h-0 after:left-full after:top-1/2 after:-translate-y-1/2 after:w-0 before:absolute before:border before:border-[8px] before:border-transparent before:border-l-slate-200 before:content-[\'\'] before:h-0 before:left-full before:top-1/2 before:-translate-y-1/2 before:w-0 !bg-white relative' : openFilter === 'date' }"
                                       v-on:click="openFilter = 'date'"
                                    >
                                        {{ __('Date') }}
                                    </a>
                                    <a class="border-b border-slate-200 cursor-pointer px-2 py-4"
                                       v-bind:class="{ 'after:absolute after:border after:border-[7px] after:border-transparent after:border-l-white after:content-[\'\'] after:h-0 after:left-full after:top-1/2 after:-translate-y-1/2 after:w-0 before:absolute before:border before:border-[8px] before:border-transparent before:border-l-slate-200 before:content-[\'\'] before:h-0 before:left-full before:top-1/2 before:-translate-y-1/2 before:w-0 !bg-white relative' : openFilter === 'partner' }"
                                       v-on:click="openFilter = 'partner'"
                                    >
                                        {{ __('Partner') }}
                                    </a>
                                    <a class="border-b border-slate-200 cursor-pointer px-2 py-4"
                                       v-bind:class="{ 'after:absolute after:border after:border-[7px] after:border-transparent after:border-l-white after:content-[\'\'] after:h-0 after:left-full after:top-1/2 after:-translate-y-1/2 after:w-0 before:absolute before:border before:border-[8px] before:border-transparent before:border-l-slate-200 before:content-[\'\'] before:h-0 before:left-full before:top-1/2 before:-translate-y-1/2 before:w-0 !bg-white relative' : openFilter === 'ticket' }"
                                       v-on:click="openFilter = 'ticket'"
                                    >
                                        {{ __('Ticket') }}
                                    </a>
                                    <a class="cursor-pointer px-2 py-4"
                                       v-bind:class="{ 'after:absolute after:border after:border-[7px] after:border-transparent after:border-l-white after:content-[\'\'] after:h-0 after:left-full after:top-1/2 after:-translate-y-1/2 after:w-0 before:absolute before:border before:border-[8px] before:border-transparent before:border-l-slate-200 before:content-[\'\'] before:h-0 before:left-full before:top-1/2 before:-translate-y-1/2 before:w-0 !bg-white relative' : openFilter === 'assignee' }"
                                       v-on:click="openFilter = 'assignee'"
                                    >
                                        {{ __('Assignee') }}
                                    </a>
                                </nav>
                                <div class="flex-col grow hidden px-5 py-2"
                                     v-bind:class="{ '!flex' : openFilter === 'date' }"
                                >
                                    <div class="mb-5">
                                        <c-control-radio name="filters.date.speeddial"
                                                         grid="md:grid-cols-2"
                                                         v-bind:options="filtersSpeedial.options"
                                                         v-model="filtersSpeedial.date"
                                                         v-on:change="updateDateFilter"
                                        />
                                    </div>
                                    <c-control-date name="filters.date"
                                                    icon="calendar"
                                                    icon-position="right"
                                                    multi-calendars
                                                    range
                                                    v-bind:enable-time-picker="false"
                                                    v-bind:format="localizationStore.dateFormat"
                                                    v-model="filters.date"
                                    />
                                </div>
                                <div class="hidden px-5 py-2 w-full"
                                     v-bind:class="{ '!flex' : openFilter === 'ticket' }"
                                >
                                    <c-control-autocomplete class="w-full" name="filters.ticket"
                                                            label-field="title"
                                                            v-bind:placeholder="__('Start typing...')"
                                                            v-bind:source-get="ticketGet"
                                                            v-bind:source-search="ticketSearch"
                                                            v-model="filtersTicket"
                                    />
                                </div>
                                <div class="hidden px-5 py-2 w-full"
                                     v-bind:class="{ '!flex' : openFilter === 'partner' }"
                                >
                                    <c-control-autocomplete class="w-full" name="filters.partner"
                                                            label-field="name"
                                                            v-bind:placeholder="__('Start typing...')"
                                                            v-bind:source-get="partnerGet"
                                                            v-bind:source-search="partnerSearch"
                                                            v-model="filtersPartner"
                                    />
                                </div>
                                <div class="hidden px-5 py-2 w-full"
                                     v-bind:class="{ '!flex' : openFilter === 'assignee' }"
                                >
                                    <c-control-autocomplete class="w-full" name="filters.assignee"
                                                            avatar-field="avatar"
                                                            label-field="name"
                                                            v-bind:placeholder="__('Search by employee name')"
                                                            v-bind:source-get="employeeGet"
                                                            v-bind:source-search="employeeSearch"
                                                            v-model="filtersEmployee"
                                    />
                                </div>
                            </div>
                            <div class="border-t border-slate-200 flex justify-end p-2">
                                <c-button class="hover:decoration-dotted hover:underline mr-2 p-1.5 text-error" v-on:click="resetFilters">
                                    {{ __('Reset filters') }}
                                </c-button>
                                <c-button class="bg-highlight p-1.5 shrink-0 text-white" v-on:click="filter">
                                    {{ __('Apply filters') }}
                                </c-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <c-button class="bg-white border border-highlight !p-2 shrink-0 text-highlight"
                      v-if="can('worksheets.inventory.export')"
                      v-on:click="exportXls"
            >
                <c-icon class="h-5 mr-2 stroke-highlight w-5" icon="download-cloud" />
                {{ __('Export') }}
            </c-button>
        </div>
    </div>
    <div class="container px-1">
        <table class="mb-10 w-full" v-if="Object.keys(inventory).length > 0">
            <thead>
                <tr class="bg-slate-100 border-y border-slate-200 text-left text-sm">
                    <th class="p-2.5">{{ __('Ticket') }}</th>
                    <th class="p-2.5">{{ __('Product') }}</th>
                    <th class="p-2.5">{{ __('Quantity') }}</th>
                    <th class="p-2.5">{{ __('Profit') }}</th>
                </tr>
            </thead>
            <tbody class="bg-white border-b border-slate-200 text-sm" v-for="ticket in inventory">
                <tr v-for="(product, key) in ticket">
                    <td class="align-top p-2" v-if="key === 0" v-bind:rowspan="ticket.length">
                        <span class="block font-bold text-success text-sm">#{{ product.worksheetOid }}</span>
                        {{ product.ticketTitle }}
                    </td>
                    <td class="p-2">
                        <span class="block text-slate-500 text-xs" v-if="product.code">{{ product.code }}</span>
                        {{ product.name }}
                    </td>
                    <td class="p-2">{{ product.quantity }} {{ __('units.' + product.um) }}</td>
                    <td class="p-2">
                        {{
                            product.profit
                            ? currencyStore.format(product.profit, sessionStore.user.tenant.config.currency)
                            : '-'
                        }}
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="flex flex-col items-center justify-center text-center" v-else>
            <img class="mb-5" src="/img/empty-state.png" srcset="/img/empty-state.png 2x"
                 v-bind:alt="__('There are no results available.')"
            />
            <div class="flex flex-col items-center justify-center text-center">
                <span class="font-semibold mb-2 text-center text-lg text-slate-500">{{ __('There are no results available.') }}</span>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { inject, onMounted, ref } from 'vue';
    import { vOnClickOutside } from '@vueuse/components';
    import debounce from 'debounce';
    import { DateTime } from 'luxon';
    import {
        CButton,
        CControlAutocomplete,
        CControlDate,
        CControlRadio,
        CControlText,
        CIcon,
    } from '@teamfurther/cinderblock';

    import EmployeeRepository from '../../repositories/EmployeeRepository';
    import PartnerRepository from '../../repositories/PartnerRepository';
    import WorksheetInventoryRepository from '../../repositories/WorksheetInventoryRepository';
    import { useCurrencyStore } from '../../stores/currency';
    import { useLocalizationStore } from '../../stores/localization';
    import { useSessionStore } from '../../stores/session';
    import TicketRepository from '../../repositories/TicketRepository';

    const __ = inject('__');

    const closeFilters = [
        (e) => {
            if (!e.target.className?.toString().startsWith('dp__')) {
                openFilters.value = false;
            }
        }
    ];

    const currencyStore = useCurrencyStore();
    const employeeRepository = EmployeeRepository.getInstance();

    const filters = ref<object>({
        date : [
            DateTime.now().startOf('month'),
            DateTime.now(),
        ],
    });
    const filtersEmployee = ref<object>();
    const filtersPartner = ref<object>();

    const filtersSpeedial = ref<object>({
        date : 'this_month',
        options: [
            { label : __('Today'), value : 'today' },
            { label : __('Yesterday'), value : 'yesterday' },
            { label : __('This week'), value : 'this_week' },
            { label : __('Last week'), value : 'last_week' },
            { label : __('This month'), value : 'this_month' },
            { label : __('Last month'), value : 'last_month' },
            { label : __('This year'), value : 'this_year' },
            { label : __('Last year'), value : 'last_year' },
        ],
    });

    const filtersTicket = ref<object>();
    const inventory = ref<object>({});
    const localizationStore = useLocalizationStore();
    const openFilter = ref<string>('date');
    const openFilters = ref<boolean>(false);
    const partnerRepository = PartnerRepository.getInstance();
    const sessionStore = useSessionStore();
    const s = ref<string>('');

    const search = debounce(async () => {
        if (s.value) {
            filters.value.search = s.value;
        } else {
            delete filters.value.search;
        }

        await getResults();
    }, 250);

    const ticketRepository = TicketRepository.getInstance();
    const unitOptions = ref<object[]>([]);
    const worksheetInventoryRepository = WorksheetInventoryRepository.getInstance();

    async function employeeGet(employeeId) {
        if (!employeeId.value) {
            return null;
        }

        return await employeeRepository.show(employeeId.value.id);
    }

    async function employeeSearch(val) {
        return await employeeRepository.index({
            filters : {
                inactive: 'with',
                search: val
            }
        });
    }

    async function exportXls() {
        await worksheetInventoryRepository.export({
            filters: filters.value,
        });
    }

    function filter() {
        getResults();

        openFilters.value = false;
    }

    async function getResults() {
        if (filtersEmployee.value) {
            filters.value.assignee_id = filtersEmployee.value.id;
        } else {
            delete filters.value.assignee_id;
        }

        if (filtersPartner.value) {
            filters.value.partner_id = filtersPartner.value.id;
        } else {
            delete filters.value.partner_id;
        }

        if (filtersTicket.value) {
            filters.value.ticket_id = filtersTicket.value.id;
        } else {
            delete filters.value.ticket_id;
        }

        inventory.value = await worksheetInventoryRepository.index({
            filters: filters.value,
        });
    }

    async function partnerGet(partnerId) {
        if (!partnerId.value) {
            return null;
        }

        return await partnerRepository.show(partnerId.value.id);
    }

    async function partnerSearch(val) {
        return await partnerRepository.index({
            filters : {
                search: val
            }
        });
    }

    function resetFilters() {
        filters.value = {
            date : [
                DateTime.now().startOf('month'),
                DateTime.now(),
            ],
        };
        filtersEmployee.value = null;
        filtersTicket.value = null;
        filtersSpeedial.value.date = 'this_month';
        s.value = '';

        openFilters.value = false;

        getResults();
    }

    async function ticketGet(ticketId) {
        if (!ticketId.value) {
            return null;
        }

        return await ticketRepository.show(ticketId.value.id);
    }

    async function ticketSearch(val) {
        return await ticketRepository.index({
            filters : {
                search: val
            }
        });
    }

    function updateDateFilter() {
        const dates = (() => {
            switch (filtersSpeedial.value.date) {
                case 'today':
                    return [
                        DateTime.now(),
                        DateTime.now(),
                    ];
                case 'yesterday':
                    return [
                        DateTime.now().minus({ days: 1}),
                        DateTime.now().minus({ days: 1}),
                    ];
                case 'this_week':
                    return [
                        DateTime.now().startOf('week'),
                        DateTime.now(),
                    ];
                case 'last_week':
                    return [
                        DateTime.now().startOf('week').minus({ days: 7 }),
                        DateTime.now().startOf('week').minus({ days: 1 }),
                    ];
                case 'this_month':
                    return [
                        DateTime.now().startOf('month'),
                        DateTime.now(),
                    ];
                case 'last_month':
                    return [
                        DateTime.now().startOf('month').minus({ month: 1 }),
                        DateTime.now().startOf('month').minus({ days: 1 }),
                    ];
                case 'this_year':
                    return [
                        DateTime.now().startOf('year'),
                        DateTime.now(),
                    ];
                case 'last_year':
                    return [
                        DateTime.now().startOf('year').minus({ year: 1 }),
                        DateTime.now().startOf('year').minus({ days: 1 }),
                    ];
            }
        })();

        if (dates) {
            filters.value.date = dates;
        } else {
            delete filters.value.date;
        }
    }

    onMounted(async () => {
        await getResults();

        unitOptions.value = sessionStore.user.tenant.config.units.split(', ').map(unit => {
            return {
                label: __('units.' + unit),
                value: unit,
            }
        });
    });
</script>
