import { inject } from 'vue';
import { useRoute } from 'vue-router';
import { useRequestStore } from '../stores/request';

export default class Repository {
    private static instance: Repository | null = null;

    protected __ = inject('__');

    protected basePath = null;

    protected messages = {
        destroy: this.__('Delete successful.'),
        store: this.__('Creation successful.'),
        update: this.__('Update successful.'),
    }

    protected redirects = {
        store: { path : '/' }
    }

    protected request = useRequestStore();
    private route = useRoute();

    public static getInstance(): Repository {
        return this.instance || (this.instance = new this());
    }

    protected buildPath(path: string, params: object = null, config: object = null): string {
        let paramObj = new URLSearchParams();
        if (params) {
            Object.keys(params).forEach(function (key) {
                switch (key) {
                    case 'append':
                        paramObj.set('append', params.append.join(','));

                        break;
                    case 'filters':
                        Object.keys(params.filters).forEach(key => {
                            if (params.filters[key]) {
                                paramObj.set('filter[' + key + ']', params.filters[key]);
                            }
                        });

                        break;
                    case 'include':
                        paramObj.set('include', params.include.join(','));

                        break;
                    case 'sort':
                        paramObj.set('sort', params.sort.replace(/^\++/, ''));

                        break;
                    default:
                        paramObj.set(key, params[key]);
                }
            });
        }

        return paramObj.toString() !== ''
            ? path + '?' + paramObj.toString()
            : path;
    }

    public destroy(id: string, data: object = null, config: object = null): Promise {
        if (!this.basePath) {
            console.error('Request: no basePath defined.');

            return;
        }

        return new Promise((resolve, reject) => {
            this.request.destroy(this.buildPath(this.basePath + '/' + id), {
                ...config,
                data: data
            })
            .then((response : any) => {
                this.request.message = this.messages.destroy;
                this.request.status = 200;
                this.request.title = this.__('Success');

                resolve(response.data);
            })
            .catch(error => reject(error));
        });
    }

    public export(params: object = null, config: object = null): Promise {
        if (!this.basePath) {
            console.error('Request: no basePath defined.');

            return;
        }

        return new Promise((resolve, reject) => {
            this.request.get(this.buildPath(this.basePath + '/export', params), config)
                .then((response : any) => {
                    const blob = new Blob([
                        new Uint8Array([0xEF, 0xBB, 0xBF]), // UTF-8 BOM
                        response.data
                    ]);
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;

                    a.download = 'export.csv';
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                })
                .catch(error => reject(error));
        });
    }

    public import(data: object, config: object = null) {
        if (!this.basePath) {
            console.error('Request: no basePath defined.');

            return;
        }

        return new Promise((resolve, reject) => {
            this.request.post(this.buildPath(this.basePath + '/import'), data, {
                ...config,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then((response : any) => {
                    this.request.redirect({
                        ...this.redirects.import,
                        ...{
                            query: this.route.query
                        }
                    }, {
                        message : this.messages.import,
                        status : 200,
                        title : this.__('Success'),
                    });

                    resolve(response.data);
                })
                .catch(error => reject(error));
        });
    }

    public index(params: object = null, config: object = null): Promise {
        if (!this.basePath) {
            console.error('Request: no basePath defined.');

            return;
        }

        return new Promise((resolve, reject) => {
            this.request.get(this.buildPath(this.basePath, params), config)
                .then((response : any) => {
                    resolve(response.data);
                })
                .catch(error => reject(error));
        });
    }

    public show(id: string, params: object = null, config: object = null): Promise {
        if (!this.basePath) {
            console.error('Request: no basePath defined.');

            return;
        }

        return new Promise((resolve, reject) => {
            this.request.get(this.buildPath(this.basePath + '/' + id, params), config)
                .then((response : any) => {
                    resolve(response.data);
                })
                .catch(error => reject(error));
        });
    }

    public store(data: object, params: object = null, config: object = null) {
        if (!this.basePath) {
            console.error('Request: no basePath defined.');

            return;
        }

        return new Promise((resolve, reject) => {
            this.request.post(this.buildPath(this.basePath, params), data, config)
                .then((response : any) => {
                    if (this.redirects.store) {
                        this.request.redirect({
                            ...this.substituteParams(this.redirects.store, response.data),
                            ...{
                                query: this.route.query
                            }
                        }, {
                            message: this.messages.store,
                            status: 200,
                            title: this.__('Success'),
                        });
                    } else {
                        this.request.message = this.messages.store;
                        this.request.status = 200;
                        this.request.title = this.__('Success');
                    }

                    resolve(response.data);
                })
                .catch(error => reject(error));
        });
    }

    public update(id: string, data: object, params: object = null, config: object = null) {
        if (!this.basePath) {
            console.error('Request: no basePath defined.');

            return;
        }

        return new Promise((resolve, reject) => {
            this.request.put(this.buildPath(this.basePath + '/' + id, params), data, config)
                .then((response : any) => {
                    this.request.message = this.messages.update;
                    this.request.status = 200;
                    this.request.title = this.__('Success');

                    resolve(response.data);
                })
                .catch(error => reject(error));
        });
    }

    private substituteParams(redirect: object, data: object): object {
        if (redirect.params) {
            Object.keys(redirect.params).forEach((key) => {
                redirect.params[key] = redirect.params[key].replace(/\$([A-Za-z0-9]+)/g, (match, p) => {
                    return data[p];
                });
            });
        }

        return redirect;
    }
}
