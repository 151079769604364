<template>
    <div class="container px-1 py-5">
        <div class="flex justify-between mb-5 md:mb-10">
            <h1 class="lg:text-3xl text-2xl">{{ site.name }}</h1>

            <div class="shrink-0" v-if="!floatSave">
                <c-button class="bg-slate-200 mr-2 text-slate-500" router v-bind:to="{ name : 'partners.edit' }">
                    {{ __('Back') }}
                </c-button>
                <c-button class="bg-success lg:min-w-[200px] text-white" dusk="save-button" v-on:click="submit">
                    {{ __('Save') }}
                </c-button>
            </div>
            <c-button class="bg-success fixed right-5 rounded-full top-5 z-10" v-else v-on:click="submit">
                <c-icon class="h-6 stroke-white w-6" icon="save" />
            </c-button>
        </div>

        <div class="bg-white mb-4 p-2 rounded shadow-sm">
            <h2 class="font-medium mb-5 text-lg">{{ __('Partner site details') }}</h2>
            <c-control-text class="mb-10" name="name" required
                            v-bind:invalid="!!requestStore.errors?.name"
                            v-bind:label="__('Site name')"
                            v-model="site.name"
            />

            <div class="grid lg:grid-cols-3 gap-10 mb-10">
                <c-control-select name="country" required
                                  v-bind:invalid="!!requestStore.errors?.country"
                                  v-bind:label="__('Country')"
                                  v-bind:options="countries"
                                  v-model="site.country"
                />
                <c-control-text name="postcode" required
                                v-bind:invalid="!!requestStore.errors?.postcode"
                                v-bind:label="__('Postcode')"
                                v-bind:placeholder="__('placeholders.postcode')"
                                v-if="site.country === 'HU'"
                                v-model="site.postcode"
                />

                <c-control-text name="county" required
                                v-bind:invalid="!!requestStore.errors?.county"
                                v-bind:label="__('County')"
                                v-bind:placeholder="__('placeholders.county')"
                                v-else
                                v-model="site.county"
                />

                <c-control-text name="city" required
                                v-bind:invalid="!!requestStore.errors?.city"
                                v-bind:label="__('City')"
                                v-bind:placeholder="__('placeholders.city')"
                                v-model="site.city"
                />
            </div>

            <div class="grid lg:grid-cols-2 gap-10">
                <c-control-text name="address_line_1" required
                                v-bind:invalid="!!requestStore.errors?.address_line_1"
                                v-bind:label="__('Address line 1')"
                                v-bind:placeholder="__('Street name, number')"
                                v-model="site.address_line_1"
                />

                <c-control-text name="address_line_2"
                                v-bind:invalid="!!requestStore.errors?.address_line_2"
                                v-bind:label="__('Address line 2')"
                                v-bind:placeholder="__('Apartment, building, floor, suite')"
                                v-model="site.address_line_2"
                />
            </div>
        </div>

        <div class="bg-white mb-4 p-2 rounded shadow-sm">
            <div class="flex items-center justify-between mb-5">
                <h2 class="font-medium text-lg">{{ __('Contact persons') }}</h2>

                <c-button class="bg-highlight shrink-0 text-white"
                          router
                          v-if="can('partners.update')"
                          v-bind:to="{ name : 'partners.sites.contacts.create' }"
                >
                    + {{ __('Add contact person') }}
                </c-button>
            </div>

            <ul class="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4" dusk="partner-site-contacts-list">
                <li class="bg-white border border-slate-200 group/item flex items-center leading-tight relative rounded shadow-sm"
                    v-for="(contact, key) in site.contacts"
                >
                    <router-link class="flex h-16 items-center justify-start pl-2 pr-8 py-2 w-full"
                                 v-bind:to="{ name : 'partners.sites.contacts.edit', params : { contact : contact.id, partner: partnerId } }"
                                 v-if="can('partners.update')"
                    >
                        <span class="line-clamp-3 text-sm">{{ contact.name }}</span>
                    </router-link>

                    <div class="absolute -bottom-1 group/card-actions right-1"
                         dusk="kebab-menu"
                         v-if="can('partners.update')"
                    >
                        <span class="cursor-pointer flex h-6 hover:bg-slate-200 items-center justify-center mb-2 rounded w-6"
                              dusk="contact-actions"
                              v-on:click="showCardActions = []; showCardActions[contact.id] = !showCardActions[contact.id]"
                              v-on-click-outside="key === 0 ? closeCardActions : [() => {}]"
                        >
                            <c-icon class="h-5 pointer-events-none stroke-slate-800 w-5" icon="more-vertical" />
                        </span>
                        <div class="absolute bg-slate-700 border border-slate-200 bottom-0 hidden overflow-hidden right-0 rounded shadow-sm translate-y-full z-10"
                             v-bind:class="{ '!block' : showCardActions[contact.id] }"
                        >
                            <router-link class="border-b border-b-slate-500 flex hover:bg-slate-800 items-center p-2 text-white text-xs whitespace-nowrap"
                                         dusk="edit-contact-button"
                                         v-bind:to="{ name : 'partners.sites.contacts.edit', params : { contact : contact.id, partner: partnerId } }"
                                         v-if="can('partners.update')"
                            >
                                <c-icon class="h-4 mr-2 stroke-white w-4" icon="edit-3" />{{ __('Edit') }}
                            </router-link>
                            <a class="cursor-pointer flex hover:bg-slate-800 items-center p-2 text-red-300 text-xs whitespace-nowrap"
                               dusk="delete-contact-button"
                               v-if="can('partners.update')"
                               v-on:click="deletedContact = contact.id"
                            >
                                <c-icon class="h-4 mr-2 stroke-red-300 w-4" icon="trash-2" />{{ __('Delete') }}
                            </a>
                        </div>
                    </div>
                </li>
                <li class="bg-white border border-slate-200 group/item flex items-center leading-tight relative rounded shadow-sm">
                    <router-link class="flex h-16 items-center justify-center p-2 w-full"
                                 v-bind:to="{ name : 'partners.sites.contacts.create', partner: partnerId }"
                                 v-if="can('partners.update')"
                    >
                        <c-icon class="group-hover:stroke-slate-500 h-10 stroke-slate-300 w-10" icon="plus" />
                    </router-link>
                </li>
            </ul>
        </div>

        <div class="bg-white p-2 rounded shadow-sm" v-if="site.serial_numbers && site.serial_numbers.length > 0">
            <h2 class="font-medium mb-5 text-lg">{{ __('Serial numbers') }}</h2>

            <ul class="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4">
                <li class="bg-white border border-slate-200 group/item flex items-center leading-tight relative rounded shadow-sm"
                    v-for="(sn, key) in site.serial_numbers"
                >
                    <router-link class="flex flex-col h-16 justify-center pl-2 pr-8 py-2 relative w-full"
                                 v-bind:to="{ name : 'devices.sn.edit', params : { pn : sn.part_number_id, sn: sn.id } }"
                                 v-if="can('devices.update')"
                    >
                        <span class="font-semibold line-clamp-1 mb-1"
                              v-if="sn.part_number.make || sn.part_number.model"
                        >
                            {{ sn.part_number.make }} {{ sn.part_number.model }}
                        </span>
                        <span class="overflow-hidden text-ellipsis text-sm text-slate-500 whitespace-nowrap">
                            {{ sn.sn }}
                        </span>

                        <span class="absolute cursor-help right-1 top-1"
                              v-if="sn.needs_maintenance"
                              v-on:mouseover="snTooltip = sn.id"
                              v-on:mouseout="snTooltip = null"
                        >
                            <c-icon class="h-6 stroke-error w-6" icon="alert-triangle" />
                            <c-tooltip position="top" v-bind:active="snTooltip === sn.id">
                                {{ __('Needs maintenance.') }}
                            </c-tooltip>
                        </span>
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
    <c-modal class="md:w-[450px]" v-bind:open="deletedContact !== null" v-bind:title="__('Delete contact person')" v-on:closeModal="deletedContact = null">
        <template v-slot:body>
            <div class="py-5 text-center text-sm">
                {{ __('Are you sure you want to delete this contact person?') }}
            </div>
        </template>
        <template v-slot:footer>
            <div class="flex justify-end w-full">
                <c-button class="bg-slate-100 mr-2 text-slate-800" v-on:click="deletedContact = null">
                    {{ __('Cancel') }}
                </c-button>
                <c-button class="bg-error text-white" dusk="delete-contact-modal-button" v-on:click="del">
                    {{ __('Delete') }}
                </c-button>
            </div>
        </template>
    </c-modal>
</template>

<script setup lang="ts">
    import { onMounted, ref } from 'vue';
    import { useRoute } from 'vue-router';
    import { vOnClickOutside } from '@vueuse/components';
    import {
        CButton,
        CControlSelect,
        CControlText,
        CIcon,
        CModal,
        CTooltip,
    } from '@teamfurther/cinderblock';

    import PartnerSiteRepository from '../../../repositories/PartnerSiteRepository';
    import PartnerSiteContactRepository from '../../../repositories/PartnerSiteContactRepository';
    import { useGeoStore } from '../../../stores/geo';
    import { useRequestStore } from '../../../stores/request';

    const closeCardActions = [
        (e) => {
            if (
                !e.target.parentNode.className?.toString().includes('group/card-actions')
            ) {
                showCardActions.value = [];
            }
        }
    ];

    const countries = ref([]);
    const deletedContact = ref(null);
    const floatSave = ref<boolean>(false);
    const geoStore = useGeoStore();
    const route = useRoute();
    const partnerId = route.params.partner;
    const partnerSiteRepository = PartnerSiteRepository.getInstance();
    const partnerSiteContactRepository = PartnerSiteContactRepository.getInstance();
    const requestStore = useRequestStore();
    const showCardActions = ref<boolean[]>([]);
    const snTooltip = ref<string | null>(null);

    const site = ref<object>({
        address_line_1 : '',
        address_line_2 : '',
        city : '',
        contacts: [],
        country : JSON.parse(window.localStorage.getItem('user')).tenant.country,
        county: '',
        name: '',
        postcode : '',
    });

    const siteId = route.params.site;

    async function del() {
        await partnerSiteContactRepository.destroy(deletedContact.value);

        let contactsTemp = site.value.contacts;
        site.value.contacts.forEach((partner, key) => {
            if (partner.id === deletedContact.value) {
                contactsTemp.splice(key, 1);
            }
        });
        site.value.contacts = contactsTemp;

        deletedContact.value = null;
    }

    async function submit() {
        await partnerSiteRepository.update(siteId, site.value, {
            include: ['contacts', 'serialNumbers']
        });
    }

    onMounted(async () => {
        countries.value = await geoStore.countries();

        site.value = await partnerSiteRepository.show(siteId, {
            include: ['contacts', 'serialNumbers', 'serialNumbers.partNumber']
        });

        window.addEventListener('scroll', () => {
            floatSave.value = window.scrollY > 100;
        });
    });
</script>
