<template>
    <div class="container px-1 py-5">
        <div class="flex justify-between mb-5 md:mb-10">
            <h1 class="lg:text-3xl text-2xl">{{ __('New employee') }}</h1>

            <div class="shrink-0">
                <c-button class="bg-slate-200 mr-2 text-slate-500" dusk="cancel-button" router
                          v-bind:to="{ name : 'employees' }"
                >
                    {{ __('Cancel') }}
                </c-button>
                <c-button class="bg-success lg:min-w-[200px] text-white" dusk="save-button" v-on:click="submit">
                    {{ __('Save') }}
                </c-button>
            </div>
        </div>

        <div class="grid lg:grid-cols-3 gap-10">
            <div class="bg-white col-span-2 mb-4 p-2 rounded shadow-sm">
                <h2 class="font-medium mb-5 text-lg">{{ __('Employee details') }}</h2>
                <c-control-text class="mb-10" name="employee.name" required
                                v-bind:invalid="!!requestStore.errors?.name"
                                v-bind:label="__('Name')"
                                v-bind:placeholder="__('placeholders.person_name')"
                                v-model="employee.name"
                />
                <c-control-text class="mb-10" name="employee.email" required
                                v-bind:invalid="!!requestStore.errors?.email"
                                v-bind:label="__('Email')"
                                v-bind:placeholder="__('placeholders.email')"
                                v-model="employee.email"
                />
                <c-control-select name="employee.role" required
                                  v-bind:invalid="!!requestStore.errors?.role"
                                  v-bind:label="__('Role')"
                                  v-bind:options="roles"
                                  v-model="employee.role"
                />
            </div>

            <div>
                <h2 class="font-medium mb-5 text-lg">{{ __('Attached documents') }}</h2>
                <div class="grid grid-cols-2 gap-4">
                    <a class="bg-white border border-slate-200 cursor-pointer duration-500 flex group hover:border-slate-500 items-center justify-center p-2 rounded transition-all"
                       v-on:click="upload"
                    >
                        <c-icon class="group-hover:stroke-slate-500 h-10 stroke-slate-300 w-10" icon="plus" />
                    </a>

                    <a class="bg-white border border-slate-200 cursor-pointer duration-500 flex flex-col group hover:border-slate-500 items-center justify-center p-2 relative rounded transition-all" target="_blank"
                       v-bind:href="employee.files[key]"
                       v-for="(file, key) in employee.filenames"
                    >
                        <img class="max-h-32 rounded"
                             v-bind:alt="file"
                             v-bind:src="employee.files[key]"
                             v-if="isImage(file)"
                        />
                        <c-icon class="group-hover:stroke-slate-500 h-10 stroke-slate-300 w-10" icon="file"
                            v-else
                        />

                        <span class="block mt-1 overflow-hidden text-center text-ellipsis text-xs whitespace-nowrap w-full">
                            {{ file }}
                        </span>

                        <span class="absolute bg-error cursor-pointer hidden group-hover:block p-1 -right-2 rounded-full text-sm -top-2 text-error" dusk="delete-file-button"
                              v-on:click.prevent="deleteFile(key)"
                        >
                            <c-icon class="h-4 stroke-white w-4" icon="trash-2" />
                        </span>
                    </a>
                    <div class="flex justify-between mt-1">
                        <span class="overflow-hidden text-ellipsis text-xs">{{ file }}</span>
                        <a class="cursor-pointer hidden group-hover:block text-sm text-error"
                           v-on:click="deleteFile(key)"
                        >
                            <c-icon class="h-4 stroke-error w-4" icon="trash-2" />
                        </a>
                    </div>
                </div>

                <c-control-file class="h-0 opacity-0 pointer-events-none w-0" multiple name="employee.files" v-bind:filenames="employee.filenames" v-model="employee.files" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { inject, onMounted, ref } from 'vue';
    import {
        CButton,
        CControlFile,
        CControlSelect,
        CControlText,
        CIcon,
    } from '@teamfurther/cinderblock';

    import { useRequestStore } from '../../stores/request';
    import EmployeeRepository from '../../repositories/EmployeeRepository';
    import RoleRepository from '../../repositories/RoleRepository';

    const __ = inject('__');

    const employee = ref<object>({
        name: '',
        role: null,
        email: '',
        files: [],
        filenames: [],
    });
    const employeeRepository = EmployeeRepository.getInstance();

    const requestStore = useRequestStore();

    const roleRepository = RoleRepository.getInstance();
    const roles = ref<Object[]>([]);

    function deleteFile(key: number) {
        employee.value.filenames.splice(key, 1);
        employee.value.files.splice(key, 1);
    }

    async function submit() {
        await employeeRepository.store(employee.value, {
            include: ['files', 'roles']
        });
    }

    function upload() {
        document.getElementById('employee.files').click();
    }

    onMounted(async () => {
        const rolesTemp = await roleRepository.index();
        roles.value = rolesTemp.map((role) => {
            return {
                label : __('role.' + role['name']),
                value : role['id'],
            }
        });
    });
</script>
