import Repository from './Repository';

export default class RoleRepository extends Repository {
    protected basePath = 'roles';

    public destroy(id: string, data: object = null): Promise {
        console.error('Destroy method does not exist on RoleRepository.');
    }

    public export(params: object = null): Promise {
        console.error('Export method does not exist on RoleRepository.');
    }

    public import(data: object): Promise {
        console.error('Import method does not exist on RoleRepository.');
    }

    public show(id: string, params: object = null): Promise {
        console.error('Show method does not exist on RoleRepository.');
    }

    public store(data: object, params: object = null): Promise {
        console.error('Store method does not exist on RoleRepository.');
    }

    public update(id: string, data: object, params: object = null): Promise {
        console.error('Update method does not exist on RoleRepository.');
    }
}