<template>
    <div class="container px-1 py-5" dusk="archive-header">
        <div class="flex flex-col items-start justify-between mb-10 md:flex-row md:items-center">
            <div class="flex flex-col mb-5 md:mb-0 md:flex-row md:items-center w-full">
                <h1 class="lg:text-3xl mb-5 md:mb-0 mr-10 text-2xl">{{ __('Tickets') }}</h1>

                <c-control-text
                    class="grow-0 mb-5 md:max-w-[300px] md:mb-0 md:mr-10"
                    icon="search"
                    name="search"
                    v-bind:placeholder="__('Start typing...')"
                    v-model="s"
                    v-on:keyup="search"
                />

                <div class="flex items-center justify-between">
                    <div class="relative">
                        <a class="cursor-pointer duration-500 flex hover:decoration-dotted hover:underline items-center p-1 rounded text-sm transition-all"
                           dusk="filter-button"
                           v-bind:class="{ 'bg-highlight text-white' : Object.keys(filters).length }"
                           v-on:click="openFilters = true"
                        >
                            <c-icon class="h-5 mr-2 stroke-slate-800 w-5" icon="filter"
                                    v-bind:class="{ '!stroke-white' : Object.keys(filters).length }"
                            />
                            {{ __('Filter results') }}
                            <span class="bg-white flex font-bold h-4 items-center justify-center ml-1 rounded-full text-center text-highlight text-xs w-4"
                                  v-if="Object.keys(filters).length"
                            >
                                {{ Object.keys(filters).length }}
                            </span>
                        </a>
                        <div class="absolute bg-white border border-slate-200 hidden md:w-[500px] mt-1 rounded shadow-lg w-[calc(100vw-0.5rem)] z-30"
                             v-bind:class="{ '!block' : openFilters }"
                             v-on-click-outside="closeFilters"
                        >
                            <div class="flex">
                                <nav class="bg-slate-50 border-r border-slate-200 flex flex-col shrink-0 text-sm">
                                    <a class="border-b border-slate-200 cursor-pointer px-2 py-4"
                                       v-bind:class="{ 'after:absolute after:border after:border-[7px] after:border-transparent after:border-l-white after:content-[\'\'] after:h-0 after:left-full after:top-1/2 after:-translate-y-1/2 after:w-0 before:absolute before:border before:border-[8px] before:border-transparent before:border-l-slate-200 before:content-[\'\'] before:h-0 before:left-full before:top-1/2 before:-translate-y-1/2 before:w-0 !bg-white relative' : openFilter === 'worksheet-id' }"
                                       v-on:click="openFilter = 'worksheet-id'"
                                       dusk="filter-worksheet-id-tab"
                                    >
                                        {{ __('Worksheet #') }}
                                    </a>
                                    <a class="border-b border-slate-200 cursor-pointer px-2 py-4"
                                       v-bind:class="{ 'after:absolute after:border after:border-[7px] after:border-transparent after:border-l-white after:content-[\'\'] after:h-0 after:left-full after:top-1/2 after:-translate-y-1/2 after:w-0 before:absolute before:border before:border-[8px] before:border-transparent before:border-l-slate-200 before:content-[\'\'] before:h-0 before:left-full before:top-1/2 before:-translate-y-1/2 before:w-0 !bg-white relative' : openFilter === 'ticket-id' }"
                                       v-on:click="openFilter = 'ticket-id'"
                                       dusk="filter-ticket-id-tab"
                                    >
                                        {{ __('Ticket #') }}
                                    </a>
                                    <a class="border-b border-slate-200 cursor-pointer px-2 py-4"
                                       v-bind:class="{ 'after:absolute after:border after:border-[7px] after:border-transparent after:border-l-white after:content-[\'\'] after:h-0 after:left-full after:top-1/2 after:-translate-y-1/2 after:w-0 before:absolute before:border before:border-[8px] before:border-transparent before:border-l-slate-200 before:content-[\'\'] before:h-0 before:left-full before:top-1/2 before:-translate-y-1/2 before:w-0 !bg-white relative' : openFilter === 'partner' }"
                                       v-on:click="openFilter = 'partner'"
                                       dusk="filter-partner-tab"
                                    >
                                        {{ __('Partner') }}
                                    </a>
                                    <a class="border-b border-slate-200 cursor-pointer px-2 py-4"
                                       v-bind:class="{ 'after:absolute after:border after:border-[7px] after:border-transparent after:border-l-white after:content-[\'\'] after:h-0 after:left-full after:top-1/2 after:-translate-y-1/2 after:w-0 before:absolute before:border before:border-[8px] before:border-transparent before:border-l-slate-200 before:content-[\'\'] before:h-0 before:left-full before:top-1/2 before:-translate-y-1/2 before:w-0 !bg-white relative' : openFilter === 'created_at' }"
                                       v-on:click="openFilter = 'created_at'"
                                       dusk="filter-created-at-tab"
                                    >
                                        {{ __('Ticket creation date') }}
                                    </a>
                                    <a class="border-b border-slate-200 cursor-pointer px-2 py-4"
                                       v-bind:class="{ 'after:absolute after:border after:border-[7px] after:border-transparent after:border-l-white after:content-[\'\'] after:h-0 after:left-full after:top-1/2 after:-translate-y-1/2 after:w-0 before:absolute before:border before:border-[8px] before:border-transparent before:border-l-slate-200 before:content-[\'\'] before:h-0 before:left-full before:top-1/2 before:-translate-y-1/2 before:w-0 !bg-white relative' : openFilter === 'updated_at' }"
                                       v-on:click="openFilter = 'updated_at'"
                                       dusk="filter-updated-at-tab"
                                    >
                                        {{ __('Ticket last modified date') }}
                                    </a>
                                    <a class="cursor-pointer px-2 py-4"
                                       v-bind:class="{ 'after:absolute after:border after:border-[7px] after:border-transparent after:border-l-white after:content-[\'\'] after:h-0 after:left-full after:top-1/2 after:-translate-y-1/2 after:w-0 before:absolute before:border before:border-[8px] before:border-transparent before:border-l-slate-200 before:content-[\'\'] before:h-0 before:left-full before:top-1/2 before:-translate-y-1/2 before:w-0 !bg-white relative' : openFilter === 'assignee' }"
                                       v-on:click="openFilter = 'assignee'"
                                       dusk="filter-assignee-tab"
                                    >
                                        {{ __('Primary assignee') }}
                                    </a>
                                </nav>
                                <div class="hidden px-5 py-2 w-full"
                                     v-bind:class="{ '!flex' : openFilter === 'worksheet-id' }"
                                >
                                    <c-control-text class="w-full"
                                                    name="filters.worksheet_id"
                                                    v-bind:placeholder="__('Search')"
                                                    v-model="filtersWorksheetId"
                                                    dusk="worksheet-id-filter-text"
                                    />
                                </div>
                                <div class="hidden px-5 py-2 w-full"
                                     v-bind:class="{ '!flex' : openFilter === 'ticket-id' }"
                                >
                                    <c-control-text class="w-full"
                                                    name="filters.ticket_id"
                                                    v-bind:placeholder="__('Search')"
                                                    v-model="filtersTicketId"
                                                    dusk="ticket-id-filter-text"
                                    />
                                </div>
                                <div class="hidden px-5 py-2 w-full"
                                     v-bind:class="{ '!flex' : openFilter === 'partner' }"
                                >
                                    <c-control-autocomplete class="w-full"
                                                            icon="search"
                                                            label-field="name"
                                                            name="filters.partner"
                                                            v-bind:placeholder="__('Search by partner name or tax ID')"
                                                            v-bind:source-get="partnerGet"
                                                            v-bind:source-search="partnerSearch"
                                                            v-model="filtersPartner"
                                                            dusk="partner-filter-autocomplete"
                                    />
                                </div>
                                <div class="flex-col grow hidden px-5 py-2"
                                     v-bind:class="{ '!flex' : openFilter === 'created_at' }"
                                >
                                    <div class="mb-5">
                                        <c-control-radio name="filters.created_at.speeddial"
                                                         grid="md:grid-cols-2"
                                                         v-bind:options="filtersSpeedial.options"
                                                         v-model="filtersSpeedial.created_at"
                                                         v-on:change="updateDateFilter('created_at')"
                                                         dusk="created-at-filter-options"
                                        />
                                    </div>
                                    <c-control-date name="filters.created_at"
                                                    icon="calendar"
                                                    icon-position="right"
                                                    multi-calendars
                                                    range
                                                    v-bind:enable-time-picker="false"
                                                    v-bind:format="localizationStore.dateFormat"
                                                    v-model="filters.created_at"
                                    />
                                </div>
                                <div class="flex-col grow hidden px-5 py-2"
                                     v-bind:class="{ '!flex' : openFilter === 'updated_at' }"
                                >
                                    <div class="mb-5">
                                        <c-control-radio name="filters.updated_at.speeddial"
                                                         grid="md:grid-cols-2"
                                                         v-bind:options="filtersSpeedial.options"
                                                         v-model="filtersSpeedial.updated_at"
                                                         v-on:change="updateDateFilter('updated_at')"
                                                         dusk="updated-at-filter-options"
                                        />
                                    </div>
                                    <c-control-date name="filters.updated_at"
                                                    icon="calendar"
                                                    icon-position="right"
                                                    multi-calendars
                                                    range
                                                    v-bind:enable-time-picker="false"
                                                    v-bind:format="localizationStore.dateFormat"
                                                    v-model="filters.updated_at"
                                    />
                                </div>
                                <div class="hidden px-5 py-2 w-full"
                                     v-bind:class="{ '!flex' : openFilter === 'assignee' }"
                                >
                                    <c-control-autocomplete class="w-full" name="filters.assignee"
                                                            avatar-field="avatar"
                                                            label-field="name"
                                                            v-bind:placeholder="__('Search by employee name')"
                                                            v-bind:source-get="employeeGet"
                                                            v-bind:source-search="employeeSearch"
                                                            v-model="filtersEmployee"
                                                            dusk="assignee-filter-autocomplete"
                                    />
                                </div>
                            </div>
                            <div class="border-t border-slate-200 flex justify-end p-2">
                                <c-button class="hover:decoration-dotted hover:underline mr-2 p-1.5 text-error" v-on:click="resetFilters" dusk="reset-filters-button">
                                    {{ __('Reset filters') }}
                                </c-button>
                                <c-button class="bg-highlight p-1.5 shrink-0 text-white" v-on:click="filter" dusk="apply-filters-button">
                                    {{ __('Apply filters') }}
                                </c-button>
                            </div>
                        </div>
                    </div>

                    <router-link class="flex hover:decoration-dotted hover:underline items-center md:ml-10 text-highlight text-sm"
                                 v-bind:to="{ name : 'tickets' }"
                                 v-if="can('tickets.index.all|tickets.index.owned|tickets.index.assigned')"
                                 dusk="to-active-tickets-button"
                    >
                        <c-icon class="h-5 mr-2 stroke-highlight w-5" icon="columns" />
                        {{ __('Show active tickets') }}
                    </router-link>
                </div>
            </div>
        </div>
    </div>
    <div class="container px-1" dusk="archive-body">
        <c-data-table responsive-breakpoint="lg"
                      v-bind:data="tableData"
                      v-if="tableData.items.length > 0"
                      v-on:sort-table="sortTable"
        >
            <template v-slot:item.oid="{ item }">
                <span class="text-slate-500 text-xs">#{{ item.values.oid }}</span>
                <span class="text-success text-xs" v-if="item.values.worksheet && item.values.worksheet.oid">
                    #{{ item.values.worksheet.oid }}
                </span>
            </template>
            <template v-slot:item.title="{ item }">
                <span class="block">{{ item.values.title }}</span>
                <a class="hover:decoration-dotted hover:underline text-highlight text-xs"
                   v-bind:href="'/tenants/' + sessionStore.user.tenant.id + '/worksheets/' + item.values.worksheet.id + '.pdf'"
                    v-if="item.values.worksheet && item.values.worksheet.signed_at"
                >
                    {{ __('Download PDF worksheet') }}
                </a>
            </template>
            <template v-slot:item.partner="{ item }">
                {{ item.values.site.partner.name }}
            </template>
            <template v-slot:item.assignee="{ item }">
                <div class="flex items-center">
                    <img class="h-6 rounded w-6 mr-2 order-first"
                         v-if="item.values.assignee?.avatar"
                         v-bind:src="item.values.assignee.avatar" v-bind:alt="item.values.assignee.name"
                    />
                    {{ item.values.assignee?.name }}
                </div>
            </template>
            <template v-slot:item.created_at="{ item }">
                {{ DateTime.fromISO(
                    item.values.created_at,
                    { locale : localizationStore.locale }
                ).toRelative() }}
            </template>
            <template v-slot:item.archived_at="{ item }">
                {{ DateTime.fromISO(
                    item.values.archived_at,
                    { locale : localizationStore.locale }
                ).toRelative() }}
            </template>
            <template v-slot:item.actions="{ item }">
                <div class="flex items-center">
                    <a class="cursor-pointer hover:decoration-dotted hover:underline text-highlight text-xs"
                       v-if="can('tickets.archive.update.all|tickets.archive.update.owned')"
                       v-on:click="unarchivedTicket = item.values.id"
                       dusk="unarchive-ticket-button"
                    >
                        {{ __('Unarchive') }}
                    </a>
                    <a class="cursor-pointer hover:decoration-dotted hover:underline ml-5 text-red-500 text-xs"
                       v-if="can('tickets.archive.destroy.all|tickets.archive.destroy.owned')"
                       v-on:click="deletedTicket = item.values.id"
                       dusk="delete-archived-ticket-button"
                    >
                        {{ __('Delete') }}
                    </a>
                </div>
            </template>
        </c-data-table>
        <div class="flex flex-col items-center justify-center text-center" v-else>
            <img class="mb-5" src="/img/empty-state.png" srcset="/img/empty-state.png 2x"
                 v-bind:alt="__('There are no tickets available.')"
            />
            <div class="flex flex-col items-center justify-center text-center">
                <span class="font-semibold text-center text-lg text-slate-500">{{ __('There are no tickets available.') }}</span>
            </div>
        </div>
    </div>

    <c-modal class="md:w-[450px]" v-bind:open="unarchivedTicket !== null" v-bind:title="__('Unarchive ticket')" v-on:closeModal="unarchivedTicket = null">
        <template v-slot:body>
            <div class="py-5 text-center text-sm">
                {{ __('Are you sure you want to archive this ticket?') }}
            </div>
        </template>
        <template v-slot:footer>
            <div class="flex justify-end w-full">
                <c-button class="bg-slate-100 mr-2 text-slate-800" v-on:click="unarchivedTicket = null">
                    {{ __('Cancel') }}
                </c-button>
                <c-button class="bg-highlight text-white" v-on:click="unarchive" dusk="unarchive-ticket-modal-button">
                    {{ __('Unarchive') }}
                </c-button>
            </div>
        </template>
    </c-modal>
    <c-modal class="md:w-[450px]" v-bind:open="deletedTicket !== null" v-bind:title="__('Delete ticket')" v-on:closeModal="deletedTicket = null">
        <template v-slot:body>
            <div class="py-5 text-center text-sm">
                {{ __('Are you sure you want to delete this ticket?') }}
            </div>
        </template>
        <template v-slot:footer>
            <div class="flex justify-end w-full">
                <c-button class="bg-slate-100 mr-2 text-slate-800" v-on:click="deletedTicket = null">
                    {{ __('Cancel') }}
                </c-button>
                <c-button class="bg-error text-white" v-on:click="del" dusk="delete-ticket-modal-button">
                    {{ __('Delete') }}
                </c-button>
            </div>
        </template>
    </c-modal>
</template>

<script setup lang="ts">
    import { inject, onMounted, ref } from 'vue';
    import { vOnClickOutside } from '@vueuse/components';
    import debounce from 'debounce';
    import { DateTime } from 'luxon';
    import {
        CButton,
        CControlAutocomplete,
        CControlDate,
        CControlRadio,
        CControlText,
        CDataTable,
        CIcon,
        CModal,
    } from '@teamfurther/cinderblock';

    import EmployeeRepository from '../../repositories/EmployeeRepository';
    import PartnerRepository from '../../repositories/PartnerRepository';
    import TicketRepository from '../../repositories/TicketRepository';
    import { useLocalizationStore } from '../../stores/localization';
    import { useSessionStore } from '../../stores/session';

    const __ = inject('__');

    const closeFilters = [
        (e) => {
            if (!e.target.className?.toString().startsWith('dp__')) {
                openFilters.value = false;
            }
        }
    ];

    const deletedTicket = ref(null);
    const employeeRepository = EmployeeRepository.getInstance();

    const filters = ref<object>({});
    const filtersEmployee = ref<object>();
    const filtersPartner = ref<object>();

    const filtersSpeedial = ref<object>({
        created_at : 'anytime',
        options: [
            { label : __('Today'), value : 'today' },
            { label : __('Yesterday'), value : 'yesterday' },
            { label : __('This week'), value : 'this_week' },
            { label : __('Last week'), value : 'last_week' },
            { label : __('This month'), value : 'this_month' },
            { label : __('Last month'), value : 'last_month' },
            { label : __('This year'), value : 'this_year' },
            { label : __('Last year'), value : 'last_year' },
            { label : __('Anytime'), value : 'anytime' },
        ],
        updated_at : 'anytime',
    });

    const filtersTicketId = ref<object>();
    const filtersWorksheetId = ref<object>();
    const localizationStore = useLocalizationStore();
    const openFilter = ref<string>('worksheet-id');
    const openFilters = ref<boolean>(false);
    const pagesCurrent = ref(1);
    const pagesTotal = ref(1);
    const partnerRepository = PartnerRepository.getInstance();

    const s = ref<string>('');

    const search = debounce(async () => {
        if (s.value) {
            filters.value.search = s.value;
        } else {
            delete filters.value.search;
        }

        await getTickets();
    }, 250);

    const sessionStore = useSessionStore();
    const sort = ref<string>('oid');

    const tableData = ref<object>({
        columnNames: {
            oid: __('ID'),
            title: __('Ticket title'),
            partner: __('Partner'),
            assignee: __('Assignee'),
            created_at: __('Created'),
            archived_at: __('Archived'),
            actions: __('Actions')
        },
        defaultSort: '+oid',
        items: [],
        sortableColumns: ['oid', 'title', 'archived_at', 'created_at', 'partner'],
    });

    const ticketRepository = TicketRepository.getInstance();
    const unarchivedTicket = ref(null);

    async function del() {
        await ticketRepository.destroy(deletedTicket.value);

        getTickets();

        deletedTicket.value = null;
    }

    async function employeeGet(employeeId) {
        if (!employeeId.value) {
            return null;
        }

        return await employeeRepository.show(employeeId.value.id);
    }

    async function employeeSearch(val) {
        return await employeeRepository.index({
            filters : {
                inactive: 'with',
                search: val
            }
        });
    }

    function filter() {
        getTickets();

        openFilters.value = false;
    }

    async function getTickets() {
        if (filtersEmployee.value) {
            filters.value.assignee_id = filtersEmployee.value.id;
        } else {
            delete filters.value.assignee_id;
        }

        if (filtersPartner.value) {
            filters.value.partner_id = filtersPartner.value.id;
        } else {
            delete filters.value.partner_id;
        }

        if (filtersTicketId.value) {
            filters.value.oid = filtersTicketId.value;
        } else {
            delete filters.value.oid;
        }

        if (filtersWorksheetId.value) {
            filters.value.worksheet_oid = filtersWorksheetId.value;
        } else {
            delete filters.value.worksheet_oid;
        }

        const result = await ticketRepository.index({
            filters: {
                ...filters.value,
                ...{
                    is_archived : 1
                }
            },
            include: ['assignee', 'site', 'site.partner', 'worksheet'],
            page: pagesCurrent.value,
            paginate: 1,
            sort: sort.value,
        });

        pagesCurrent.value = result.current_page;
        pagesTotal.value = result.last_page;

        tableData.value.items = result.data.map((ticket) => {
            return { values : ticket }
        });
    }

    async function partnerGet(partnerId) {
        if (!partnerId.value) {
            return null;
        }

        return await partnerRepository.show(partnerId.value.id);
    }

    async function partnerSearch(val) {
        return await partnerRepository.index({
            filters : {
                search: val
            }
        });
    }

    function resetFilters() {
        filters.value = {};
        filtersEmployee.value = null;
        filtersPartner.value = null;
        filtersSpeedial.value.created_at = 'anytime';
        filtersSpeedial.value.updated_at = 'anytime';
        filtersTicketId.value = null;
        filtersWorksheetId.value = null;
        s.value = '';

        getTickets();
    }

    async function sortTable(sortBy: string) {
        sort.value = sortBy;

        await getTickets();
    }

    async function unarchive() {
        await ticketRepository.update(unarchivedTicket.value, {
            archived_at : null
        });

        getTickets();

        unarchivedTicket.value = null;
    }

    function updateDateFilter(field : string) {
        const dates = (() => {
            switch (filtersSpeedial.value[field]) {
                case 'today':
                    return [
                        DateTime.now(),
                        DateTime.now(),
                    ];
                case 'yesterday':
                    return [
                        DateTime.now().minus({ days: 1}),
                        DateTime.now().minus({ days: 1}),
                    ];
                case 'this_week':
                    return [
                        DateTime.now().startOf('week'),
                        DateTime.now(),
                    ];
                case 'last_week':
                    return [
                        DateTime.now().startOf('week').minus({ days: 7 }),
                        DateTime.now().startOf('week').minus({ days: 1 }),
                    ];
                case 'this_month':
                    return [
                        DateTime.now().startOf('month'),
                        DateTime.now(),
                    ];
                case 'last_month':
                    return [
                        DateTime.now().startOf('month').minus({ month: 1 }),
                        DateTime.now().startOf('month').minus({ days: 1 }),
                    ];
                case 'this_year':
                    return [
                        DateTime.now().startOf('year'),
                        DateTime.now(),
                    ];
                case 'last_year':
                    return [
                        DateTime.now().startOf('year').minus({ year: 1 }),
                        DateTime.now().startOf('year').minus({ days: 1 }),
                    ];
            }
        })();

        if (dates) {
            filters.value[field] = dates;
        } else {
            delete filters.value[field];
        }
    }

    onMounted(async () => {
        await getTickets();
    });
</script>
