import { useSessionStore }  from '../stores/session';

export const hasRole = {
    install: (app, options) => {
        app.config.globalProperties.hasRole = function (value: string): boolean {
            const sessionStore = useSessionStore();

            if (! sessionStore.user['role_list']) {
                return false;
            }

            const roles: string[] = sessionStore.user['role_list'];
            let response = false;

            if (!Array.isArray(roles)) {
                return false;
            }

            if (value.indexOf('|') >= 0) {
                value.split('|').forEach(function (item: string) {
                    if (roles.indexOf(item.trim()) >= 0) {
                        response = true;
                    }
                });
            } else if (value.indexOf('&') >= 0) {
                response = true;
                value.split('&').forEach(function (item: string) {
                    if (roles.indexOf(item.trim()) === -1) {
                        response = false;
                    }
                });
            } else {
                response = roles.indexOf(value.trim()) >= 0;
            }

            return response;
        };
    },
};