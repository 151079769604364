import Repository from './Repository';

export default class PartNumberRepository extends Repository {
    protected basePath = 'devices/pn';

    protected messages = {
        destroy : this.__('Part number successfully deleted.'),
        import : this.__('Part numbers successfully imported.'),
        store : this.__('Part number successfully created.'),
        update : this.__('Part number successfully updated.'),
    }

    protected redirects = {
        import: { name : 'devices.pn' },
        store: { name : 'devices.pn.edit', params: { pn: '$id' } },
    }

    public export(params: object = null): Promise {
        console.error('Export method does not exist on PartNumberRepository.');
    }
}