<template>
    <div class="container px-1 py-5">
        <div class="flex flex-col items-end justify-between lg:flex-row lg:items-center mb-10" dusk="employees-header">
            <div class="flex flex-col justify-between lg:justify-start lg:mb-0 mb-5 md:flex-row md:items-center w-full">
                <h1 class="lg:text-3xl mb-5 md:mb-0 mr-10 text-2xl">{{ __('Employees') }}</h1>
                <c-control-text
                    class="grow md:max-w-[300px] mb-5 md:mb-0 md:mr-10"
                    icon="search"
                    name="search"
                    v-bind:placeholder="__('Search by employee name')"
                    v-model="s"
                    v-on:keyup="search"
                />

                <c-control-select icon="search" label-field="name" name="filters.role_id"
                                  v-bind:options="roles"
                                  v-model="filters.role_id"
                                  v-on:change="filter"
                />
            </div>

            <c-button class="bg-highlight shrink-0 text-white"
                      dusk="create-employee-button"
                      router
                      v-bind:to="{ name : 'employees.create' }"
                      v-if="can('users.store')"
            >
                + {{ __('Add employee') }}
            </c-button>
        </div>
        <ul class="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4" dusk="employees-list" v-if="employees.length > 0">
            <li class="bg-white border border-slate-200 flex group/item items-center leading-tight relative rounded shadow-sm"
                v-for="(employee, key) in employees"
            >
                <router-link class="flex h-16 items-center justify-start pl-2 pr-8 py-2 relative w-full"
                             v-bind:to="{ name : 'employees.edit', params: { employee : employee.id } }"
                             v-if="can('users.show|users.update')"
                >
                    <img class="h-8 mr-2 -mt-px rounded w-8" v-bind:src="employee.avatar" />
                    <span class="line-clamp-3 text-sm">{{ employee.name }}</span>
                </router-link>
                <div class="flex h-16 items-center justify-start pl-2 pr-8 py-2 relative w-full"
                     v-if="!can('users.show|users.update')"
                >
                    <img class="h-8 mr-2 -mt-px rounded w-8" v-bind:src="employee.avatar" />
                    <span class="line-clamp-3 text-sm">{{ employee.name }}</span>
                </div>

                <span class="absolute border-b-[12px] border-r-[14px] border-r-success border-transparent cursor-help h-0 right-0 rounded-tr top-0 w-0"
                      v-if="can('users.activate') && employee.is_active"
                      v-on:mouseover="tooltipActive = employee.id"
                      v-on:mouseout="tooltipActive = null"
                >
                    <c-tooltip position="top" v-bind:active="tooltipActive === employee.id">
                        {{ __('Active users count towards billable seats.') }}
                    </c-tooltip>
                </span>
                <span class="absolute border-b-[12px] border-r-[14px] border-r-slate-200 border-transparent cursor-help h-0 right-0 rounded-tr top-0 w-0"
                      v-if="can('users.activate') && !employee.is_active"
                      v-on:mouseover="tooltipActive = employee.id"
                      v-on:mouseout="tooltipActive = null"
                >
                    <c-tooltip position="top" v-bind:active="tooltipActive === employee.id">
                        {{ __('Inactive users don\'t count towards billable seats.') }}
                    </c-tooltip>
                </span>

                <div class="absolute -bottom-1 group/card-actions right-1">
                    <span class="cursor-pointer flex h-6 hover:bg-slate-200 items-center justify-center mb-2 rounded w-6"
                          dusk="employee-actions"
                          v-if="can('users.show|users.update|users.destroy')"
                          v-on:click="showCardActions = []; showCardActions[employee.id] = !showCardActions[employee.id]"
                          v-on-click-outside="key === 0 ? closeCardActions : [() => {}]"
                    >
                        <c-icon class="h-5 pointer-events-none stroke-slate-800 w-5" icon="more-vertical" />
                    </span>
                    <div class="absolute bg-slate-700 border border-slate-200 bottom-0 hidden overflow-hidden right-0 rounded shadow-sm translate-y-full z-10"
                         v-bind:class="{ '!block' : showCardActions[employee.id] }"
                    >
                        <router-link class="border-b border-b-slate-500 flex hover:bg-slate-800 items-center last:border-b-0 p-2 text-white text-xs whitespace-nowrap"
                                     dusk="edit-employee-button"
                                     v-bind:to="{ name : 'employees.edit', params: { employee : employee.id } }"
                                     v-if="can('users.show|users.update')"
                        >
                            <c-icon class="h-4 mr-2 stroke-white w-4" icon="edit-3" />{{ __('Edit') }}
                        </router-link>

                        <a class="border-b border-b-slate-500 cursor-pointer flex hover:bg-slate-800 items-center last:border-b-0 p-2 text-white text-xs"
                           dusk="activate-employee-button whitespace-nowrap"
                           v-if="can('users.activate') && !employee.is_active"
                           v-on:click="updateEmployeeActiveStatus(employee.id, true)"
                        >
                            <c-icon class="h-4 mr-2 stroke-white w-4" icon="eye" />{{ __('Activate') }}
                        </a>
                        <a class="border-b border-b-slate-500 cursor-pointer flex hover:bg-slate-800 items-center last:border-b-0 p-2 text-white text-xs"
                           dusk="deactivate-employee-button whitespace-nowrap"
                           v-if="can('users.activate') && employee.is_active && employee.id !== sessionStore.user.id && !employee.is_owner"
                           v-on:click="updateEmployeeActiveStatus(employee.id, false)"
                        >
                            <c-icon class="h-4 mr-2 stroke-white w-4" icon="eye-off" />{{ __('Deactivate') }}
                        </a>
                        <a class="cursor-pointer flex hover:bg-slate-800 items-center p-2 text-red-300 text-xs"
                           dusk="delete-employee-button whitespace-nowrap"
                           v-if="can('users.destroy') && employee.id !== sessionStore.user.id && !employee.is_owner"
                           v-on:click="deletedEmployee = employee.id"
                        >
                            <c-icon class="h-4 mr-2 stroke-red-300 w-4" icon="trash-2" />{{ __('Delete') }}
                        </a>
                    </div>
                </div>
            </li>
        </ul>
        <div class="flex flex-col items-center justify-center text-center" v-else>
            <img class="mb-5" src="/img/empty-state.png" srcset="/img/empty-state.png 2x"
                 v-bind:alt="__('There are no employees available.')"
            />
            <div class="flex flex-col items-center justify-center text-center">
                <span class="font-semibold mb-2 text-center text-lg text-slate-500">{{ __('There are no employees available.') }}</span>
                <router-link class="text-center text-sm text-highlight"
                             dusk="add-employee-button"
                             v-bind:to="{ name : 'employees.create' }"
                             v-if="can('users.store')"
                >
                    + {{ __('Add employee') }}
                </router-link>
            </div>
        </div>
    </div>
    <c-modal class="md:w-[450px]" v-bind:open="deletedEmployee !== null" v-bind:title="__('Delete employee')" v-on:closeModal="deletedEmployee = null">
        <template v-slot:body>
            <div class="py-5 text-center text-sm">
                {{ __('Are you sure you want to delete this employee?') }}
            </div>
        </template>
        <template v-slot:footer>
            <div class="flex justify-end w-full">
                <c-button class="bg-slate-100 mr-2 text-slate-800" v-on:click="deletedEmployee = null">
                    {{ __('Cancel') }}
                </c-button>

                <c-button class="bg-error text-white" dusk="delete-employee-modal-button" v-on:click="del">
                    {{ __('Delete') }}
                </c-button>
            </div>
        </template>
    </c-modal>
</template>

<script setup lang="ts">
    import { inject, onMounted, ref } from 'vue';
    import { vOnClickOutside } from '@vueuse/components';
    import debounce from 'debounce';
    import {
        CButton,
        CControlSelect,
        CControlText,
        CIcon,
        CModal,
        CTooltip,
    } from '@teamfurther/cinderblock';

    import EmployeeRepository from '../../repositories/EmployeeRepository';
    import RoleRepository from '../../repositories/RoleRepository';
    import { useSessionStore } from '../../stores/session';

    const __ = inject('__');

    const closeCardActions = [
        (e) => {
            if (
                !e.target.parentNode.className?.toString().includes('group/card-actions')
            ) {
                showCardActions.value = [];
            }
        }
    ];

    const deletedEmployee = ref(null);

    const employeeRepository = EmployeeRepository.getInstance();
    const employees = ref<Object[]>([]);

    const filters = ref<object>({
        role_id: '',
    });

    const roleRepository = RoleRepository.getInstance();
    const roles = ref<Object[]>([]);

    const s = ref<string>('');
    const search = debounce(async () => {
        employees.value = await employeeRepository.index({
            filters: {
                inactive: 'with',
                search : s.value,
            },
        });
    }, 250);

    const sessionStore = useSessionStore();

    const showCardActions = ref<boolean[]>([]);
    const tooltipActive = ref();

    async function del() {
        await employeeRepository.destroy(deletedEmployee.value);

        sessionStore.refreshUser();

        let employeesTemp = employees.value;
        employees.value.forEach((employee, key) => {
            if (employee.id === deletedEmployee.value) {
                employeesTemp.splice(key, 1);
            }
        });
        employees.value = employeesTemp;

        deletedEmployee.value = null;
    }

    async function filter() {
        employees.value = await employeeRepository.index({
            filters: {
                ...filters.value,
                ...{
                    inactive : 'with'
                }
            },
        });
    }

    async function updateEmployeeActiveStatus(id: string, status: boolean) {
        await employeeRepository.update(id, {
            is_active: status,
        });

        employees.value.forEach((employee, key) => {
            if (employee.id === id) {
                employees.value[key].is_active = status;
            }
        });

        sessionStore.refreshUser();
    }

    onMounted(async () => {
        employees.value = await employeeRepository.index({
            filters: {
                inactive: 'with',
            },
        });

        const rolesTemp = await roleRepository.index();
        roles.value = rolesTemp.map((role) => {
            return {
                label : __('role.' + role['name']),
                value : role['id'],
            }
        });
        roles.value.unshift({
            label : __('All roles'),
            value : '',
        });
    });
</script>
