<template>
    <div class="container px-1 py-5">
        <div class="flex justify-between mb-5 md:mb-10">
            <h1 class="lg:text-3xl text-2xl">{{ __('New partner') }}</h1>

            <div class="shrink-0" v-if="!floatSave">
                <c-button class="bg-slate-200 mr-2 text-slate-500" router v-bind:to="{ name : 'partners' }">
                    {{ __('Cancel') }}
                </c-button>
                <c-button class="bg-success lg:min-w-[200px] text-white" dusk="save-button" v-on:click="submit">
                    {{ __('Save') }}
                </c-button>
            </div>
            <c-button class="bg-success fixed right-5 rounded-full top-5 z-10" v-else v-on:click="submit">
                <c-icon class="h-6 stroke-white w-6" icon="save" />
            </c-button>
        </div>

        <div class="bg-white mb-4 p-2 rounded shadow-sm">
            <h2 class="font-medium mb-5 text-lg">{{ __('Partner details') }}</h2>
            <div class="grid lg:grid-cols-3 gap-10 mb-10">
                <c-control-text class="lg:col-span-2" name="partner.name" required
                                v-bind:invalid="!!requestStore.errors?.name"
                                v-bind:label="__('Name')"
                                v-bind:placeholder="__('placeholders.company_name')"
                                v-model="partner.name"
                />

                <c-control-text name="partner.tax_id"
                                v-bind:invalid="!!requestStore.errors?.tax_id"
                                v-bind:label="__('Tax ID')"
                                v-bind:placeholder="__('placeholders.tax_id')"
                                v-model="partner.tax_id"
                />
            </div>

            <div class="grid lg:grid-cols-3 gap-10 mb-10">
                <c-control-select name="country" required
                                  v-bind:invalid="!!requestStore.errors?.country"
                                  v-bind:label="__('Country')"
                                  v-bind:options="countries"
                                  v-model="partner.country"
                />
                <c-control-text name="postcode" required
                                v-bind:invalid="!!requestStore.errors?.postcode"
                                v-bind:label="__('Postcode')"
                                v-bind:placeholder="__('placeholders.postcode')"
                                v-if="partner.country === 'HU'"
                                v-model="partner.postcode"
                />

                <c-control-text name="county" required
                                v-bind:invalid="!!requestStore.errors?.county"
                                v-bind:label="__('County')"
                                v-bind:placeholder="__('placeholders.county')"
                                v-else
                                v-model="partner.county"
                />

                <c-control-text name="city" required
                                v-bind:invalid="!!requestStore.errors?.city"
                                v-bind:label="__('City')"
                                v-bind:placeholder="__('placeholders.city')"
                                v-model="partner.city"
                />
            </div>

            <div class="grid lg:grid-cols-2 gap-10 mt-10">
                <c-control-text name="address_line_1" required
                                v-bind:invalid="!!requestStore.errors?.address_line_1"
                                v-bind:label="__('Address line 1')"
                                v-bind:placeholder="__('Street name, number')"
                                v-model="partner.address_line_1"
                />

                <c-control-text name="address_line_2"
                                v-bind:invalid="!!requestStore.errors?.address_line_2"
                                v-bind:label="__('Address line 2')"
                                v-bind:placeholder="__('Apartment, building, floor, suite')"
                                v-model="partner.address_line_2"
                />
            </div>
        </div>

        <div class="bg-white mb-4 p-2 rounded shadow-sm">
            <h2 class="font-medium mb-5 text-lg">{{ __('Contact person') }}</h2>
            <div class="grid lg:grid-cols-3 gap-10">
                <c-control-text name="partner.contact_name" required
                                v-bind:invalid="!!requestStore.errors?.contact_name"
                                v-bind:label="__('Name')"
                                v-bind:placeholder="__('placeholders.person_name')"
                                v-model="partner.contact_name"
                />

                <c-control-text name="partner.contact_email"
                                v-bind:invalid="!!requestStore.errors?.contact_email"
                                v-bind:label="__('Email')"
                                v-bind:placeholder="__('placeholders.email')"
                                v-model="partner.contact_email"
                />

                <c-control-text name="partner.contact_phone"
                                v-bind:invalid="!!requestStore.errors?.contact_phone"
                                v-bind:label="__('Phone number')"
                                v-bind:placeholder="__('placeholders.phone')"
                                v-model="partner.contact_phone"
                />
            </div>
        </div>

        <div class="bg-white mb-4 p-2 rounded shadow-sm">
            <h2 class="font-medium mb-5 text-lg">{{ __('Partner sites') }}</h2>
            <p class="flex items-center text-sm">
                <c-icon class="h-6 mr-2 stroke-error w-6" icon="alert-triangle" />
                {{ __('You\'ll be able to add partner sites after saving.') }}
            </p>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { onMounted, ref } from 'vue';
    import {
        CButton,
        CControlSelect,
        CControlText,
        CIcon,
    } from '@teamfurther/cinderblock';

    import PartnerRepository from '../../repositories/PartnerRepository';
    import { useGeoStore } from '../../stores/geo';
    import { useRequestStore } from '../../stores/request';

    const countries = ref([]);

    const defaultSite = {
        address_line_1 : '',
        address_line_2 : '',
        city : '',
        country : JSON.parse(window.localStorage.getItem('user')).tenant.country,
        county: '',
        name : '',
        postcode : '',
    }

    const floatSave = ref<boolean>(false);
    const geoStore = useGeoStore();

    const partner = ref<object>({
        address_line_1 : '',
        address_line_2 : '',
        city : '',
        contact_email: '',
        contact_name: '',
        contact_phone: '',
        country : JSON.parse(window.localStorage.getItem('user')).tenant.country,
        county: '',
        name: '',
        postcode : '',
        sites: [
            { ...defaultSite }
        ],
        tax_id: ''
    });
    const partnerRepository = PartnerRepository.getInstance();

    const requestStore = useRequestStore();

    async function submit() {
        await partnerRepository.store(partner.value, {
            include: ['sites']
        });
    }

    onMounted(async () => {
        countries.value = await geoStore.countries();

        window.addEventListener('scroll', () => {
            floatSave.value = window.scrollY > 100;
        });
    });
</script>
