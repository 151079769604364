<template>
    <c-modal class="max-w-[600px]" v-bind:open="true" v-bind:title="__('New part number')" v-on:closeModal="closeDestination">
        <template v-slot:body>
            <div class="flex flex-col">
                <c-control-text class="mb-10" name="pn.pn" required
                                v-bind:invalid="!!requestStore.errors?.pn"
                                v-bind:label="__('Part number (P/N)')"
                                v-model="pn.pn"
                />

                <c-control-text class="mb-10" name="pn.make"
                                v-bind:invalid="!!requestStore.errors?.name"
                                v-bind:label="__('Make')"
                                v-model="pn.make"
                />

                <c-control-text class="mb-10" name="pn.model"
                                v-bind:invalid="!!requestStore.errors?.name"
                                v-bind:label="__('Model')"
                                v-model="pn.model"
                />
            </div>
        </template>
        <template v-slot:footer>
            <div class="bg-white flex justify-end w-full">
                <c-button class="bg-slate-100 mr-2 text-slate-800" v-on:click="closeDestination">
                    {{ __('Cancel') }}
                </c-button>
                <c-button class="bg-success text-white" v-on:click="submit">
                    {{ __('Save') }}
                </c-button>
            </div>
        </template>
    </c-modal>
</template>

<script setup lang="ts">
    import { inject, ref } from 'vue';
    import { useRouter } from 'vue-router'
    import {
        CButton,
        CControlText,
        CModal,
    } from '@teamfurther/cinderblock';

    import PartNumberRepository from '../../../repositories/PartNumberRepository';
    import { useRequestStore } from '../../../stores/request';
    import { useSessionStore } from '../../../stores/session';

    const __ = inject('__');
    const partNumberRepository = PartNumberRepository.getInstance();

    const pn = ref<object>({
        make: '',
        model: '',
        pn: '',
    });

    const requestStore = useRequestStore();
    const router = useRouter();
    const sessionStore = useSessionStore();

    function closeDestination() {
        if (!window.history.state.back) {
            router.push({ name : 'devices.pn' });
        } else {
            router.go(-1);
        }
    }

    async function submit() {
        await partNumberRepository.store(pn.value);
    }
</script>
