import Repository from './Repository';

export default class EmployeeRepository extends Repository {
    protected basePath = 'users';

    protected messages = {
        destroy : this.__('Employee successfully deleted.'),
        store : this.__('Employee successfully created.'),
        update : this.__('Employee successfully updated.'),
    }

    protected redirects = {
        store: { name : 'employees' }
    }

    public export(params: object = null): Promise {
        console.error('Export method does not exist on EmployeeRepository.');
    }

    public import(data: object): Promise {
        console.error('Import method does not exist on EmployeeRepository.');
    }
}