<template>
    <div class="duration-500 flex group hover:bg-highlight items-center mb-5 md:mb-0 -ml-1 mr-10 p-1 relative rounded self-start shrink-0 transition-all"
         v-bind:class="{ 'bg-highlight' : showMenu }"
         v-if="can('boards.store|boards.update|boards.destroy') && hasSubscription('extra')"
         v-on:click="determineShowMenu"
         v-on-click-outside="closeMenu"
    >
        <c-icon class="cursor-pointer group-hover:stroke-white h-6 mr-2 stroke-slate-800 w-6" icon="layers"
                v-bind:class="{ 'stroke-white' : showMenu }"
        />

        <h1 class="after:border-[5px] after:border-transparent after:border-t-slate-800 after:content-[''] after:duration-500 after:h-0 after:ml-2 after:mt-1.5 after:pointer-events-none after:transition-all after:w-0 cursor-pointer duration-500 flex font-bold group-hover:after:border-t-white group-hover:text-white h-6 items-center text-xs transition-all uppercase"
            v-bind:class="{ 'after:!border-t-white text-white' : showMenu }"
        >
            {{ activeBoard.name }}
        </h1>

        <nav class="absolute bg-white border border-slate-200 flex flex-col left-0 min-w-[200px] mt-2 rounded shadow-sm text-sm top-full whitespace-nowrap z-10"
             v-on:click.stop
             v-show="showMenu"
        >
            <div class="flex hover:bg-slate-50 items-center justify-between p-2 relative text-xs" draggable="true"
                 v-bind:class="{ 'bg-slate-50' : board.id === activeBoard.id }"
                 v-for="(board, key) in boards"
                 v-on:dragend="dragEnd($event)"
                 v-on:dragenter.prevent="dragging(board, $event)"
                 v-on:dragover.prevent
                 v-on:dragstart.stop="dragStart(board, $event)"
                 v-on:drop="dragDrop"
            >
                <div class="absolute bg-slate-200 h-full hidden left-0 top-0 w-full z-10"
                     v-bind:class="{ '!block' : dragEl?.id === board.id }"
                />

                <span class="block border-dotted border-l-2 border-r-2 border-slate-500 cursor-grab h-2.5 mr-4 shrink-0 w-1.5" />

                <router-link class="cursor-pointer mr-2 uppercase w-full"
                             v-bind:to="{ name : 'tickets', query : { board : board.id } }"
                             v-on:click="showMenu = false"
                >
                    {{ board.name }}
                </router-link>

                <span class="flex h-6 items-center justify-center relative rounded shrink-0 w-6"
                      v-bind:class="{ 'cursor-pointer hover:bg-slate-200 ' : !board.is_default, 'pointer-events-none' : board.is_default }"
                      v-if="can('boards.update')"
                      v-on:mouseover="showDefaultTooltip[board.id] = true"
                      v-on:mouseout="showDefaultTooltip[board.id] = false"
                      v-on:click="setBoardAsDefault(board)"
                >
                    <c-icon class="h-4 pointer-events-none stroke-slate-800 w-4" icon="bookmark"
                            v-bind:class="{ '!fill-highlight !stroke-highlight' : board.is_default }"
                    />

                    <c-tooltip class="mb-1" position="top" v-bind:active="showDefaultTooltip[board.id] === true">
                        <span class="block text-center text-xs w-full">{{ __('Set as default') }}</span>
                    </c-tooltip>
                </span>

                <div class="group/board-actions relative">
                    <span class="cursor-pointer flex h-6 hover:bg-slate-200 items-center justify-center rounded shrink-0 w-6"
                          v-if="can('boards.update|boards.destroy')"
                          v-on:click="showBoardActions = []; showBoardActions[board.id] = !showBoardActions[board.id]"
                          v-on-click-outside="key === 0 ? closeBoardActions : [() => {}]"
                    >
                        <c-icon class="h-5 pointer-events-none stroke-slate-800 w-5" icon="more-vertical" />
                    </span>
                    <div class="absolute bg-slate-700 hidden min-w-[200px] left-7 overflow-hidden rounded shadow-sm top-0 z-10"
                         v-bind:class="{ '!block' : showBoardActions[board.id] }"
                    >
                        <a class="border-b border-slate-500 cursor-pointer flex hover:bg-slate-800 items-center p-2 text-white text-xs"
                           v-if="can('boards.lists.update')"
                           v-on:click="promptRenameBoard(board)"
                        >
                            <c-icon class="h-4 mr-2 stroke-white w-4" icon="edit-3" />{{ __('Rename') }}
                        </a>
                        <a class="cursor-pointer flex hover:bg-slate-800 items-center p-2 text-red-300 text-xs"
                           v-if="can('boards.lists.destroy')"
                           v-on:click="deletedBoard = board"
                        >
                            <c-icon class="h-4 mr-2 stroke-red-300 w-4" icon="trash-2" />{{ __('Delete') }}
                        </a>
                    </div>
                </div>
            </div>

            <a class="border-t border-slate-200 cursor-pointer flex hover:bg-slate-50 items-center p-2"
               v-if="can('boards.store')"
               v-on:click="addingBoard = true"
            >
                <c-icon class="h-4 mr-2 stroke-slate-800 w-4" icon="plus" />
                {{ __('New board') }}
            </a>
        </nav>
    </div>
    <div class="flex items-center mb-5 md:mb-0 -ml-1 mr-10 p-1 rounded shrink-0"
         v-if="!hasSubscription('extra')"
    >
        <c-icon class="h-6 mr-2 stroke-slate-800 w-6" icon="layers"/>

        <h1 class="cursor-default flex font-bold h-6 items-center text-xs uppercase">
            {{ activeBoard.name }}
        </h1>
    </div>

    <div class="absolute">
        <c-modal class="md:w-[450px]"
                 v-bind:open="addingBoard"
                 v-bind:title="__('New board')"
                 v-on:closeModal="addingBoard = false"
        >
            <template v-slot:body>
                <c-control-text class="w-full" name="name" required
                                v-bind:invalid="!!requestStore.errors?.name"
                                v-bind:label="__('Board name')"
                                v-bind:placeholder="__('Start typing...')"
                                v-model="newBoardName"
                />
            </template>
            <template v-slot:footer>
                <div class="flex justify-end w-full">
                    <c-button class="bg-slate-100 mr-2 text-slate-800" v-on:click="addingBoard = false">
                        {{ __('Cancel') }}
                    </c-button>
                    <c-button class="bg-success text-white"
                              v-on:click="addBoard"
                    >
                        {{ __('Save') }}
                    </c-button>
                </div>
            </template>
        </c-modal>
        <c-modal class="md:w-[450px]"
                 v-bind:open="renamedBoard !== null"
                 v-bind:title="__('Rename board')"
                 v-on:closeModal="renamedBoard = null"
        >
            <template v-slot:body>
                <c-control-text class="w-full" name="name" required
                                v-bind:invalid="!!requestStore.errors?.name"
                                v-bind:label="__('Board name')"
                                v-bind:placeholder="__('Start typing...')"
                                v-model="newBoardName"
                />
            </template>
            <template v-slot:footer>
                <div class="flex justify-end w-full">
                    <c-button class="bg-slate-100 mr-2 text-slate-800" v-on:click="renamedBoard = null">
                        {{ __('Cancel') }}
                    </c-button>
                    <c-button class="bg-success text-white"
                              v-on:click="renameBoard"
                    >
                        {{ __('Save') }}
                    </c-button>
                </div>
            </template>
        </c-modal>
        <c-modal class="md:w-[450px]"
                 v-bind:open="deletedBoard !== null"
                 v-bind:title="__('Delete board')"
                 v-on:closeModal="deletedBoard = null"
        >
            <template v-slot:body>
                <div class="py-5 text-center text-sm">
                    {{ __('Are you sure you want to delete this board? WARNING: All tickets will be permanently deleted as well.') }}
                </div>
            </template>
            <template v-slot:footer>
                <div class="flex justify-end w-full">
                    <c-button class="bg-slate-100 mr-2 text-slate-800" v-on:click="deletedBoard = null">
                        {{ __('Cancel') }}
                    </c-button>
                    <c-button class="bg-error text-white"
                              v-on:click="delBoard"
                    >
                        {{ __('Delete') }}
                    </c-button>
                </div>
            </template>
        </c-modal>
        <c-modal class="md:w-[600px]"
                 v-bind:open="showPremiumFeatureAlert === 'multipleBoards'"
                 v-bind:title="__('You discovered a premium feature!')"
                 v-on:closeModal="closePremiumFeatureAlert('multipleBoards')"
        >
            <template v-slot:body>
                <div class="py-5 text-center text-sm">
                    {{ __('The feature you are trying to access is part of a premium package. You can experiment with it for now, but it will be inaccessible after the trial period unless you choose the EXTRA subscription plan.') }}
                </div>
            </template>
            <template v-slot:footer>
                <div class="flex justify-center w-full">
                    <c-button class="bg-warning text-white"
                              v-on:click="closePremiumFeatureAlert('multipleBoards')"
                    >
                        {{ __('Dismiss') }}
                    </c-button>
                </div>
            </template>
        </c-modal>
    </div>
</template>

<script setup lang="ts">
    import { inject, onMounted, ref } from 'vue';
    import { vOnClickOutside } from '@vueuse/components';
    import {
        CButton,
        CControlText,
        CIcon,
        CModal,
        CTooltip,
    } from '@teamfurther/cinderblock';

    import BoardRepository from '../../repositories/BoardRepository';
    import { useRequestStore } from '../../stores/request';
    import { useSessionStore } from '../../stores/session';

    const __ = inject('__');

    const addingBoard = ref<boolean>(false);
    const boardRepository = BoardRepository.getInstance();
    const boards = ref<object[]>([]);

    const closeBoardActions = [
        (e) => {
            if (
                !e.target.parentNode.className?.toString().includes('group/card-actions')
            ) {
                showBoardActions.value = [];
            }
        }
    ];

    const deletedBoard = ref(null);
    const dragEl = ref<object>();
    const dragDest = ref<object>();
    const dragImage = ref();
    const dragOrig = ref<object[]>([]);
    const dragOver = ref<object>();
    const dragPos = ref<number>();
    const newBoardName = ref<string>('');
    const renamedBoard = ref(null);
    const requestStore = useRequestStore();
    const sessionStore = useSessionStore();
    const showBoardActions = ref<boolean[]>([]);
    const showDefaultTooltip = ref<boolean[]>([]);
    const showPremiumFeatureAlert = ref<string>();
    const showMenu = ref<boolean>(false);

    const emits = defineEmits(['boardsUpdated']);

    const props = defineProps({
        activeBoard: {
            required: true,
            type: Object,
        }
    });

    async function addBoard() {
        addingBoard.value = false;

        await boardRepository.store({
            name: newBoardName.value,
            order: boards.value.length,
        }, {
            include: ['lists'],
        }).then((response) => {
            boards.value.push(response);
            newBoardName.value = __('Board #:number', { number: boards.value.length + 1 });
        }).catch(() => {
            addingBoard.value = true;
        });
    }

    function closeMenu() {
        showMenu.value = false;
    }

    function closePremiumFeatureAlert(key) {
        localStorage.setItem('hasShownPremiumFeatureAlert.' + key, true);
        showPremiumFeatureAlert.value = null;
    }

    async function delBoard() {
        await boardRepository.destroy(deletedBoard.value.id);

        deletedBoard.value = null;

        await getBoards();
    }

    function determineShowMenu() {
        if (
            !sessionStore.user.tenant?.is_subscribed
            && sessionStore.user.tenant?.is_on_generic_trial
            && !localStorage.getItem('hasShownPremiumFeatureAlert.multipleBoards')
        ) {
            showPremiumFeatureAlert.value = 'multipleBoards';
        } else {
            showMenu.value = !showMenu.value;
        }
    }

    async function dragDrop() {
        if (dragDest.value?.id !== dragEl.value.id) {
            await boardRepository.reorder({
                boards: boards.value
            });
        }

        dragEl.value = null;
    }

    function dragEnd(e) {
        if (e.dataTransfer.dropEffect === 'none') {
            boards.value = dragOrig.value;
        }

        dragImage.value.remove();
        dragEl.value = null;
        dragDest.value = null;
        dragOrig.value = [];
        dragOver.value = null;
        dragPos.value = null;
    }

    function dragging(board, e) {
        dragOver.value = board;

        const direction = e.clientX < dragPos.value ? 0 : 1;
        dragPos.value = e.clientX;

        boards.value.every((b, key) => {
            if (
                board.id !== dragEl.value.id &&
                dragEl.value.id === b.id
            ) {
                boards.value.splice(key, 1);

                return false;
            }

            return true;
        });

        boards.value.every((b, key) => {
            if (
                board.id !== dragEl.value.id &&
                board.id === b.id
            ) {
                dragDest.value = board;
                boards.value.splice(key + direction, 0, dragEl.value);

                return false;
            }

            return true;
        });
    }

    function dragStart(board, e) {
        e.dataTransfer.dropEffect = 'move';
        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setData('id', board.id);

        dragEl.value = board;

        dragImage.value = document.createElement('div');
        const clone = e.target.cloneNode(true);
        dragOrig.value = JSON.parse(JSON.stringify(boards.value));

        if (clone.style) {
            clone.style.listStyle = 'none';
            clone.style.transform = 'rotate(3deg)';
            clone.style.width = e.target.offsetWidth + 'px';
        }

        dragImage.value.style.left = '-9999px';
        dragImage.value.style.position = 'absolute';
        dragImage.value.style.top = '-9999px';
        dragImage.value.appendChild(clone);
        document.body.appendChild(dragImage.value);
        e.dataTransfer.setDragImage(dragImage.value, -100, -100);

        dragPos.value = e.clientX;
    }

    async function getBoards() {
        boards.value = await boardRepository.index({
            include: ['lists'],
        });

        newBoardName.value = __('Board #:number', { number: boards.value.length + 1 });

        emits('boardsUpdated', boards);
    }

    function promptRenameBoard(board: object) {
        renamedBoard.value = board;

        newBoardName.value = board.name;
    }

    async function renameBoard() {
        const board = renamedBoard.value;

        renamedBoard.value = null;

        await boardRepository.update(board.id, {
            name : newBoardName.value
        }).then(async () => {
            await getBoards();
        }).catch(() => {
            renamedBoard.value = board;
        });
    }

    async function setBoardAsDefault(board) {
        await boardRepository.update(board.id, {
            is_default : true
        });

        await getBoards();
    }

    onMounted(async () => {
        await getBoards();
    });
</script>