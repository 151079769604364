<template>
    <div class="container px-1 py-5" dusk="worksheet-show" v-if="worksheet">
        <div class="flex justify-between mb-5 md:mb-10">
            <h1 class="lg:text-3xl text-2xl">{{ worksheet.ticket.title }}</h1>

            <div class="shrink-0">
                <c-button class="bg-slate-200 mr-2 text-slate-500" v-on:click="router.back()">
                    {{ __('Cancel') }}
                </c-button>
                <a class="bg-highlight cursor-pointer disabled:cursor-not-allowed disabled:opacity-30 duration-300 ease-out font-bold inline-flex items-center justify-center lg:min-w-[200px] p-2.5 rounded text-xs text-white transition-all uppercase" target="_blank"
                   v-bind:href="'/tenants/' + sessionStore.user.tenant.id + '/worksheets/' + worksheet.id + '.pdf'"
                   v-if="worksheet.signed_at"
                >
                    {{ __('Download PDF worksheet') }}
                </a>
            </div>
        </div>
        <div class="flex flex-col-reverse lg:flex-row items-start">
            <div class="flex flex-col lg:mr-4 lg:w-2/3 w-full">
                <div class="bg-white p-2 rounded shadow-sm">
                    <h2 class="font-medium mb-5 text-lg">{{ __('Time worked') }}</h2>

                    <div class="gap-4 grid grid-cols-12 items-center mb-2" v-for="log in worksheet.time_logs_grouped">
                        <div class="col-span-4">
                            <img class="h-6 inline mr-2 -mt-1 rounded w-6"
                                 v-bind:src="log.assignee.avatar"
                                 v-bind:alt="log.assignee.name"
                            />
                            <span class="text-sm">{{ log.assignee.name }}</span>
                        </div>
                        <span class="col-span-8">{{ log.time_for_humans }}</span>
                    </div>

                    <strong class="block mt-5 text-sm">{{ __('Total') }}</strong>
                    <span class="block font-black text-2xl">{{ worksheet.time_for_humans }}</span>
                </div>

                <div class="bg-white mt-4 p-2 rounded shadow-sm">
                    <h2 class="font-medium mb-5 text-lg">{{ __('Scheduling') }}</h2>

                    <strong class="block mb-1 text-sm">{{ __('Start time') }}</strong>
                    <div class="flex items-center text-sm">
                        {{
                            DateTime.fromISO(
                                worksheet.created_at,
                                { locale : localizationStore.locale }
                            ).toFormat(localizationStore.dateFormat)
                        }}
                        {{ DateTime.fromISO(worksheet.created_at).toLocaleString(DateTime.TIME_24_SIMPLE) }}

                        <span class="inline-flex items-center ml-5 text-xs"
                              v-if="worksheet.ticket.schedule_start_date"
                        >
                            <span class="relative"
                                  v-on:mouseover="tooltipStartDate = true"
                                  v-on:mouseout="tooltipStartDate = false"
                            >
                                <div v-if="DateTime.fromISO(worksheet.ticket.schedule_start_date + 'T' + (worksheet.ticket.schedule_start_time ?? '00:00')).diff(DateTime.fromISO(worksheet.created_at), 'hours').toObject().hours > 1"
                                >
                                    <c-icon class="cursor-help h-6 mr-2 stroke-warning w-6" icon="alert-triangle" />
                                    <c-tooltip position="bottom" v-bind:active="tooltipStartDate">
                                        {{ __('Delay over :duration hours.', { duration: 1 }) }}
                                    </c-tooltip>
                                </div>
                                <c-icon class="h-6 mr-2 stroke-success w-6" icon="check-circle"
                                        v-else
                                />
                            </span>

                            {{ __('Scheduled') }}:&nbsp;
                            <span v-if="worksheet.ticket.schedule_start_date">
                                {{
                                    DateTime.fromISO(
                                        worksheet.ticket.schedule_start_date,
                                        { locale : localizationStore.locale }
                                    ).toFormat(localizationStore.dateFormat)
                                }}&nbsp;
                            </span>
                            <span v-if="worksheet.ticket.schedule_start_time">
                                {{ DateTime.fromISO(worksheet.ticket.schedule_start_time).toLocaleString(DateTime.TIME_24_SIMPLE) }}
                            </span>
                        </span>
                    </div>

                    <strong class="block mb-1 mt-5 text-sm" v-if="worksheet.signed_at">{{ __('End time') }}</strong>
                    <div class="flex items-center text-sm" v-if="worksheet.signed_at">
                        {{
                            DateTime.fromISO(
                                worksheet.signed_at,
                                { locale : localizationStore.locale }
                            ).toFormat(localizationStore.dateFormat)
                        }}
                        {{ DateTime.fromISO(worksheet.signed_at).toLocaleString(DateTime.TIME_24_SIMPLE) }}

                        <span class="inline-flex items-center ml-5 text-xs"
                              v-if="worksheet.ticket.schedule_end_date"
                        >
                            <span class="relative"
                                  v-on:mouseover="tooltipEndDate = true"
                                  v-on:mouseout="tooltipEndDate = false"
                            >
                                <div v-if="DateTime.fromISO(worksheet.ticket.schedule_end_date + 'T' + (worksheet.ticket.schedule_end_time ?? '00:00')).diff(DateTime.fromISO(worksheet.signed_at), 'hours').toObject().hours > 1"
                                >
                                    <c-icon class="cursor-help h-6 mr-2 stroke-warning w-6" icon="alert-triangle"/>
                                    <c-tooltip position="bottom" v-bind:active="tooltipEndDate">
                                        {{ __('Delay over :duration hours.', { duration: 1 }) }}
                                    </c-tooltip>
                                </div>
                                <c-icon class="h-6 mr-2 stroke-success w-6" icon="check-circle"
                                        v-else
                                />
                            </span>

                            {{ __('Scheduled') }}:&nbsp;
                            <span v-if="worksheet.ticket.schedule_end_date">
                                {{
                                    DateTime.fromISO(
                                        worksheet.ticket.schedule_end_date,
                                        { locale : localizationStore.locale }
                                    ).toFormat(localizationStore.dateFormat)
                                }}&nbsp;
                            </span>
                            <span v-if="worksheet.ticket.schedule_end_time">
                                {{ DateTime.fromISO(worksheet.ticket.schedule_end_time).toLocaleString(DateTime.TIME_24_SIMPLE) }}
                            </span>
                        </span>
                    </div>

                    <strong class="block mt-5 text-sm" v-if="worksheet.ticket.next_occurrence">{{ __('Recurring?') }}</strong>
                    <div class="text-sm" v-if="worksheet.ticket.next_occurrence">
                        {{ __('Every') }}
                        {{ worksheet.ticket.recurrence_interval }}
                        {{ frequencies.find((f) => f.value === worksheet.ticket.recurrence_frequency)?.label }}.<br />

                        {{ __('Next occurrence') }}:
                        {{
                            DateTime.fromISO(
                                worksheet.ticket.next_occurrence,
                                { locale : localizationStore.locale }
                            ).toFormat(localizationStore.dateFormat)
                        }}
                    </div>
                </div>

                <div class="bg-white mt-4 p-2 rounded shadow-sm">
                    <h2 class="font-medium mb-5 text-lg">{{ __('Assignees') }}</h2>

                    <strong class="block mb-1 text-sm">{{ __('Primary assignee') }}</strong>
                    <c-control-autocomplete class="mb-5" dusk="assignee-field"
                                            avatar-field="avatar" label-field="name" name="worksheet.ticket.assignee"
                                            v-bind:invalid="!!requestStore.errors?.assignee_id"
                                            v-bind:placeholder="__('Search by employee name')"
                                            v-bind:source-get="employeeGet"
                                            v-bind:source-search="employeeSearch"
                                            v-if="worksheet.signed_at === null"
                                            v-model="worksheet.ticket.assignee"
                                            v-on:update:modelValue="submit"
                    />
                    <span class="flex mb-5 items-center" v-else>
                        <img class="h-6 mr-2 rounded" v-bind:src="worksheet.ticket.assignee.avatar" v-bind:alt="worksheet.ticket.assignee.name" />
                        <span class="text-sm">{{ worksheet.ticket.assignee.name }}</span>
                    </span>

                    <strong class="block mb-1 text-sm">{{ __('Additional assignees') }}</strong>
                    <c-control-tag avatar-field="avatar" label-field="name" name="worksheet.ticket.additional_assignees"
                                   v-bind:invalid="!!requestStore.errors?.additional_assignees"
                                   v-bind:placeholder="__('Search by employee name')"
                                   v-bind:source-get="employeeGet"
                                   v-bind:source-search="employeeSearch"
                                   v-model="worksheet.ticket.additional_assignees"
                                   v-on:update:modelValue="submit"
                    />
                </div>

                <div class="bg-white mt-4 p-2 rounded shadow-sm" v-if="worksheet.files.length > 0">
                    <div class="flex items-center justify-between mb-5">
                        <h2 class="font-medium text-lg">{{ __('Uploaded files') }}</h2>
                        <c-button class="bg-slate-100 group hover:bg-highlight hover:text-white py-2 text-highlight"
                                  v-on:click="downloadFiles"
                        >
                            <c-icon class="group-hover:stroke-white h-5 mr-2 stroke-highlight w-5" icon="download-cloud" />
                            {{ __('Download all') }}
                        </c-button>
                    </div>

                    <div class="grid grid-cols-3 gap-4 md:grid-cols-6">
                        <a class="bg-white border border-slate-200 cursor-pointer duration-500 flex flex-col hover:border-slate-500 items-center justify-center p-2 rounded transition-all" target="_blank"
                           v-bind:href="file.url"
                           v-for="file in worksheet.files"
                        >
                            <img class="max-h-32 rounded"
                                 v-bind:alt="file.name"
                                 v-bind:src="file.url"
                                 v-if="isImage(file.name)"
                            />
                            <c-icon class="group-hover:stroke-slate-500 h-10 stroke-slate-300 w-10" icon="file"
                                    v-else
                            />
                            <span class="block mt-1 overflow-hidden text-center text-ellipsis text-xs whitespace-nowrap w-full">
                                {{ file.name }}
                            </span>
                        </a>
                    </div>
                </div>

                <div class="bg-white mt-4 p-2 rounded shadow-sm" v-if="worksheet.serial_numbers.length > 0">
                    <h2 class="font-medium mb-5 text-lg">{{ __('Devices') }}</h2>

                    <ul>
                        <li class="border-b border-slate-200 flex flex-col last:border-b-0 last:mb-0 last:pb-0 mb-5 md:border-b-0 md:grid md:grid-cols-3 md:mb-1 md:pb-0 pb-5 text-sm w-full"
                            v-for="sn in worksheet.serial_numbers"
                        >
                            <div class="flex items-center">
                                <c-icon class="h-4 mr-2 stroke-slate-500 w-4"
                                        v-bind:icon="sn.has_been_serviced ? 'check-square' : 'square'"
                                />

                                <div class="flex flex-col">
                                    <span>{{ sn.sn }}</span>
                                    <span class="text-slate-500 text-xs" v-if="sn.name">{{ sn.name }}</span>
                                </div>
                            </div>

                            <label class="flex items-center md:mt-0 mt-5 text-left">
                                {{ __('Usage') }}
                                <strong class="mx-2">{{ sn.usage }}</strong>
                                {{ __('hours') }}
                            </label>

                            <label class="flex items-center md:ml-10 md:mt-0 mt-5">
                                {{ __('Maintenance performed?') }}
                                <strong class="ml-2">{{ sn.maintenance_performed ? __('Yes') : __('No') }}</strong>
                            </label>
                        </li>
                    </ul>
                </div>

                <div class="bg-white mt-4 p-2 rounded shadow-sm" v-if="worksheet.tasks.length > 0">
                    <h2 class="font-medium mb-5 text-lg">{{ __('Tasks') }}</h2>
                    <ul v-if="worksheet.tasks.length > 0">
                        <li class="flex group items-center mb-1 text-sm"
                            v-for="task in worksheet.tasks"
                        >
                            <c-icon class="inline-block h-4 mr-2 stroke-slate-500 w-4"
                                    v-bind:icon="task.completed_at ? 'check-square' : 'square'"
                            />
                            <div class="relative">
                                {{ task.name }}

                                <div class="absolute after:absolute after:border-8 after:border-b-slate-700 after:border-transparent after:bottom-full after:content-[''] after:h-0 after:pointer-events-none after:left-4 after:w-0 bg-slate-700 -bottom-2 group-hover:block hidden p-1.5 md:after:border-b-transparent md:after:border-r-slate-700 md:after:bottom-auto md:after:left-auto md: after:right-full md:after:top-1/2 md:after:-translate-y-1/2 md:bottom-auto md:-right-4 md:top-1/2 md:translate-x-full md:-translate-y-1/2 rounded text-white translate-y-full w-[350px] z-10"
                                     v-if="task.notes || (task.files && task.files.length > 0)"
                                >
                                    <strong class="block mb-1 text-xs">{{ __('Notes') }}</strong>
                                    <span class="text-xs">{{ task.notes }}</span>

                                    <div class="mt-5" v-if="task.files && task.files.length > 0">
                                        <div class="flex items-center justify-between">
                                            <strong class="block mb-1 text-xs">{{ __('Files') }}</strong>

                                            <c-button class="bg-indigo-500 group hover:bg-highlight hover:text-white py-1 text-white text-xs"
                                                      v-on:click="downloadTaskFiles(task.id)"
                                            >
                                                <c-icon class="group-hover:stroke-white h-4 mr-2 stroke-highlight w-4" icon="download-cloud" />
                                                {{ __('Download all') }}
                                            </c-button>
                                        </div>
                                        <ul class="mt-2">
                                            <li class="last:mb-0 mb-1" v-for="file in task.files">
                                                <a class="flex items-center text-xs text-white" target="_blank"
                                                   v-bind:href="file.url"
                                                >
                                                    <c-icon class="h-4 mr-2 stroke-white w-4" icon="file" />

                                                    {{ file.name }}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="bg-white mt-4 p-2 rounded shadow-sm" v-if="worksheet.inventory.length > 0">
                    <h2 class="font-medium mb-5 text-lg">{{ __('Products used') }}</h2>
                    <ul v-if="worksheet.inventory.length > 0">
                        <li class="flex group items-center mb-2 text-sm w-full"
                            v-for="product in worksheet.inventory"
                        >
                            <span class="cursor-help relative"
                                  v-if="product.is_custom"
                                  v-on:mouseover="tooltipInventory = product.id"
                                  v-on:mouseout="tooltipInventory = null"
                            >
                                <c-icon class="h-6 mr-2 stroke-warning w-6" icon="alert-triangle"/>
                                <c-tooltip class="ml-2" position="right" v-bind:active="tooltipInventory === product.id">
                                    {{ __('Custom product.') }}
                                </c-tooltip>
                            </span>
                            <c-icon class="h-6 mr-2 stroke-slate-500 w-6" icon="package" v-else />
                            <span class="mr-2 w-16">{{ product.quantity }} {{ __('units.' + product.um) }}</span>
                            <span>{{ product.name }}</span>
                        </li>
                    </ul>
                </div>

                <div class="bg-white mt-4 p-2 rounded shadow-sm" v-if="worksheet.fields.length > 0">
                    <h2 class="font-medium text-lg">{{ __('Other information') }}</h2>
                    <template v-for="field in fields">
                        <div class="mt-5" v-if="fieldHasValue(field)">
                            <strong class="block mb-1 text-sm">{{ field.label }}</strong>
                            <div class="bg-slate-50 mb-2 p-2"
                                 v-for="repetition in computeFieldRepetitions(field.key)"
                                 v-if="field.fields && field.fields.length > 0"
                            >
                                <div class="gap-4 grid grid-cols-4"
                                     v-for="subfield in field.fields"
                                >
                                    <span class="block col-span-1 mb-1 text-sm">{{ subfield.label }}</span>
                                    <span class="col-span-3 text-sm">
                                        {{ findAndParseFieldValue(subfield, field.key + '.' + (repetition-1) + '.' + subfield.key) }}
                                    </span>
                                </div>
                            </div>
                            <span class="text-sm" v-else>{{ findAndParseFieldValue(field, field.key) }}</span>
                        </div>
                    </template>
                </div>
            </div>
            <div class="bg-white flex flex-col lg:mb-0 lg:w-1/3 mb-4 p-2 rounded shadow-sm w-full">
                <div class="flex items-center justify-between mb-5 w-full">
                    <div class="flex items-center">
                        <span class="mr-2 text-slate-500 text-xs">#{{ worksheet.ticket.oid }}</span>
                        <span class="text-sm">{{ worksheet.ticket.title }}</span>
                        <span class="bg-yellow-400 cursor-help ml-2 p-1 relative rounded"
                              v-if="!worksheet.signed_at"
                        >
                            <c-icon class="h-4 fill-white w-4" icon="tool" />
                        </span>
                        <span class="bg-success cursor-help ml-2 p-1 relative rounded"
                              v-else
                        >
                            <c-icon class="h-4 fill-white w-4" icon="tool" />
                        </span>
                        <span class="font-bold ml-1 text-sm text-success"
                              v-if="worksheet.oid"
                        >
                            #{{ worksheet.oid }}
                        </span>
                    </div>
                    <span class="ml-4 shrink-0 text-slate-500 text-xs">
                        {{
                            DateTime.fromISO(
                                worksheet.ticket.updated_at,
                                { locale : localizationStore.locale }
                            ).toRelative()
                        }}
                    </span>
                </div>
                <strong class="mb-1 text-sm">{{ __('Partner') }}</strong>
                <span class="text-sm">{{ worksheet.ticket.site?.partner.name }}</span>

                <strong class="mb-1 mt-5 text-sm">{{ __('Site') }}</strong>
                <div class="text-sm">
                    <span class="block">{{ worksheet.ticket.site?.address_line_1 }}</span>
                    <span class="block" v-if="worksheet.ticket.site?.address_line_2">{{ worksheet.ticket.site.address_line_2 }}</span>
                    <span class="block">
                        <span v-if="worksheet.ticket.site?.postcode">{{ worksheet.ticket.site.postcode }} </span>
                        {{ worksheet.ticket.site?.city }},
                        {{ worksheet.ticket.site?.country }}
                    </span>
                </div>

                <strong class="mb-1 mt-5 text-sm">{{ __('Ticket description') }}</strong>
                <div class="text-sm">{{ worksheet.ticket.description }}</div>

                <strong class="mb-1 mt-5 text-sm" v-if="worksheet.ticket.notes">{{ __('Notes') }}</strong>
                <div class="text-sm" v-if="worksheet.ticket.notes">{{ worksheet.ticket.notes }}</div>

                <strong class="mb-1 mt-5 text-sm">{{ __('Billable?') }}</strong>
                <span class="text-sm">{{ worksheet.is_billable ? __('Yes') : __('No') }}</span>

                <strong class="mb-1 mt-5 text-sm" v-if="worksheet.ticket.files.length > 0">{{ __('Files') }}</strong>
                <div class="grid grid-cols-3 gap-4">
                    <a class="bg-white border border-slate-200 cursor-pointer duration-500 flex flex-col hover:border-slate-500 items-center justify-center p-2 rounded transition-all" target="_blank"
                       v-bind:href="file.url"
                       v-for="file in worksheet.ticket.files"
                       v-if="worksheet.ticket.files.length > 0"
                    >
                        <img class="max-h-32 mr-2 rounded"
                             v-bind:alt="file.name"
                             v-bind:src="file.url"
                             v-if="isImage(file.name)"
                        />
                        <c-icon class="group-hover:stroke-slate-500 h-10 stroke-slate-300 w-10" icon="file"
                                v-else
                        />
                        <span class="block mt-1 overflow-hidden text-center text-ellipsis text-xs whitespace-nowrap w-full">
                            {{ file.name }}
                        </span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { inject, isRef, onMounted, ref } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { DateTime } from 'luxon';
    import {
        CButton,
        CControlAutocomplete,
        CControlTag,
        CIcon,
        CTooltip,
    } from '@teamfurther/cinderblock';

    import { useLocalizationStore } from '../../stores/localization';
    import { useRequestStore } from '../../stores/request';
    import { useSessionStore } from '../../stores/session';
    import EmployeeRepository from '../../repositories/EmployeeRepository';
    import TicketRepository from '../../repositories/TicketRepository';
    import WorksheetRepository from '../../repositories/WorksheetRepository';

    const __ = inject('__');
    const employeeRepository = EmployeeRepository.getInstance();
    const fields = ref<Object[]>([]);

    const frequencies = [
        {
            value: 'days',
            label: __('day(s)'),
        },
        {
            value: 'months',
            label: __('month(s)'),
        },
        {
            value: 'years',
            label: __('year(s)'),
        },
    ];

    const localizationStore = useLocalizationStore();
    const requestStore = useRequestStore();
    const route = useRoute();
    const router = useRouter();
    const sessionStore = useSessionStore();
    const ticketRepository = TicketRepository.getInstance();
    const tooltipEndDate = ref<boolean>(false);
    const tooltipInventory = ref();
    const tooltipStartDate = ref<boolean>(false);
    const worksheet = ref<object>();
    const worksheetId = route.params.worksheet;
    const worksheetRepository = WorksheetRepository.getInstance();

    function computeFieldRepetitions(key) {
        let repetitions = null;

        worksheet.value.fields.forEach(field => {
            const keyParts = field.key.split('.');

            if (keyParts[0] === key && (!repetitions || parseInt(keyParts[1]) + 1 > repetitions)) {
                repetitions = parseInt(keyParts[1]) + 1;
            }
        });

        return repetitions;
    }

    async function downloadFiles() {
        await worksheetRepository.downloadFiles(worksheet.value.id);
    }

    async function downloadTaskFiles(task) {
        await worksheetRepository.downloadTaskFiles(task);
    }

    async function employeeGet(employee) {
        if (isRef(employee) && employee.value) {
            return await employeeRepository.show(employee.value.id);
        } else if (employee && employee.id) {
            return await employeeRepository.show(employee.id);
        } else {
            return null;
        }
    }

    async function employeeSearch(val) {
        return await employeeRepository.index({
            filters : {
                search: val
            }
        });
    }

    function fieldHasValue(needle) {
        if (needle.widget === 'toggle') {
            return true;
        }

        for (let i = 0; i < worksheet.value.fields.length; i++) {
            const field = worksheet.value.fields[i];

            if (field.key === needle.key || field.key.split('.').shift() === needle.key) {
                return true;
            }
        }

        return false;
    }

    function findAndParseFieldValue(field, key) {
        const value = worksheet.value.fields.find(f => f.key === key)?.value;

        switch (field.widget) {
            case 'checkbox':
                if (!value) {
                    return;
                }

                const selected = value.split('|');
                let parsedValue = [];

                field.options.forEach(option => {
                    if (selected.includes(Object.keys(option)[0])) {
                        parsedValue.push(Object.values(option)[0]);
                    }
                });

                return parsedValue.join(', ');
            case 'number':
            case 'text':
            case 'textarea':
                return value ?? null;
            case 'select':
                if (!value) {
                    return;
                }

                for (let i = 0; i < field.options.length; i++) {
                    if (Object.keys(field.options[i])[0] === value) {
                        return Object.values(field.options[i])[0];
                    }
                }

                return;
            case 'toggle':
                return value === '1' ? __('Yes') : __('No');
        }
    }

    async function submit() {
        await ticketRepository.update(worksheet.value.ticket_id, {
            assignee_id: worksheet.value.ticket.assignee.id,
            additional_assignees: worksheet.value.ticket.additional_assignees,
        });
    }

    onMounted(async () => {
        worksheet.value = await worksheetRepository.show(worksheetId, {
            include: [
                'fields',
                'files',
                'inventory',
                'serialNumbers',
                'tasks',
                'tasks.files',
                'ticket',
                'ticket.additionalAssignees',
                'ticket.assignee',
                'ticket.files',
                'ticket.site',
                'ticket.site.partner',
                'time_logs.assignee',
            ]
        });

        await requestStore.get('worksheets/fields').then(async (response : any) => {
            fields.value = response.data;
        });
    });
</script>
