import Repository from './Repository';

export default class InventoryRepository extends Repository {
    protected basePath = 'inventory';

    protected messages = {
        destroy : this.__('Product successfully deleted.'),
        import : this.__('Products successfully imported.'),
        store : this.__('Product successfully created.'),
        update : this.__('Product successfully updated.'),
    }

    protected redirects = {
        import: { name : 'inventory' },
        store: { name : 'inventory' }
    }

    public export(params: object = null): Promise {
        console.error('Export method does not exist on InventoryRepository.');
    }
}